// @mui
import { Alert, Checkbox, FormControlLabel, FormGroup, Link } from '@mui/material';
import Grid from '@mui/material/Grid2';

import Box from '@mui/material/Box';
import { Controller } from 'react-hook-form';
import { useTheme } from '@mui/material/styles';
import { InfoBox } from 'src/components/InfoBox';
import { SubHeadline } from './SubHeadline';

// components

// ----------------------------------------------------------------------

// ----------------------------------------------------------------------

export default function TermsVerify({ errors, control, platformPartner }) {
  const platformPartnerPath = platformPartner === 'Greenfactory' ? 'greenfactory' : 'thgqp';
  const theme = useTheme();

  return (
    <Box sx={{ padding: '20px 0px 40px 0px' }}>
      <SubHeadline text="Bestätigung" />
      <Box sx={{ mb: 2, mt: 2 }}>
        <InfoBox
          color={theme.palette.primary.main}
          html={
            '<b>Wichtiger Hinweis: Die THG-Quotenprämie kann pro Fahrzeug nur einmal jährlich vom Fahrzeughalter beantragt werden.</b> Stellen Sie daher vor Einreichung Ihrer Daten sicher, dass weder durch den vorherigen Halter, noch durch einen anderen Anbieter die THG-Quote für den ausgewählten Abrechnungszeitraum beantragt worden ist, um Doppeleinreichungen zu vermeiden.'
          }
        />
      </Box>
      <Box sx={{ paddingTop: { xs: '10px!important', md: '0px!important' } }}>
        {' '}
        <Controller
          name="concession"
          control={control}
          rules={{
            required: true,
          }}
          render={({ field }) => (
            <FormGroup>
              <FormControlLabel
                sx={{ margin: '0px 0px' }}
                className={errors.concession ? 'error' : ''}
                control={<Checkbox sx={{ padding: '10px', paddingRight: '20px' }} {...field} />}
                label="Hiermit bestätige ich, dass ich berechtigt bin, die THG-Quotenprämie für die angegebenen Fahrzeuge zu beantragen."
              />
            </FormGroup>
          )}
        />
      </Box>
      <Box sx={{ paddingTop: { xs: '10px!important', md: '0px!important' } }}>
        {' '}
        <Controller
          name="concession2"
          control={control}
          rules={{
            required: true,
          }}
          render={({ field }) => (
            <FormGroup>
              <FormControlLabel
                sx={{ margin: '0px 0px' }}
                className={errors.concession2 ? 'error' : ''}
                control={<Checkbox sx={{ padding: '10px', paddingRight: '20px' }} {...field} />}
                label="Hiermit bestätige ich, dass für die angegebenen Fahrzeuge im ausgewählten Abrechnungszeitraum noch keine THG-Quotenprämien beantragt wurden."
              />
            </FormGroup>
          )}
        />
      </Box>

      <Box sx={{ paddingTop: { xs: '10px!important', md: '0px!important' } }}>
        <Controller
          name="privacy"
          control={control}
          rules={{
            required: true,
          }}
          render={({ field }) => (
            <FormGroup>
              <FormControlLabel
                sx={{ margin: '0px 0px' }}
                className={errors.privacy ? 'error' : ''}
                control={<Checkbox sx={{ padding: '10px', paddingRight: '20px' }} {...field} />}
                label={
                  <>
                    Ja, ich bin mit den{' '}
                    <Link href={`/${platformPartnerPath}/nutzungsbedingungen`} target="_blank" rel="noreferrer">
                      Nutzungsbedingungen
                    </Link>{' '}
                    einverstanden und habe die{' '}
                    <Link href={`/${platformPartnerPath}/datenschutz`} target="_blank" rel="noreferrer">
                      Datenschutzerklärung
                    </Link>{' '}
                    gelesen.
                  </>
                }
              />
            </FormGroup>
          )}
        />
      </Box>

      <Box sx={{ paddingTop: { xs: '10px!important', md: '0px!important' } }}>
        <Controller
          name="terms"
          control={control}
          rules={{
            required: true,
          }}
          render={({ field }) => (
            <FormGroup>
              <FormControlLabel
                sx={{ margin: '0px 0px' }}
                className={errors.terms ? 'error' : ''}
                control={<Checkbox sx={{ padding: '10px', paddingRight: '20px' }} {...field} />}
                label={
                  <>
                    Ja, ich stimme dem Vertragsabschluss und den damit einhergehenden{' '}
                    <Link href={`/thgqp/agb`} target="_blank" rel="noreferrer">
                      AGB
                    </Link>{' '}
                    der Picsure GmbH zu.
                  </>
                }
              />
            </FormGroup>
          )}
        />
      </Box>

      <Grid size={{ xs: 12, md: 6 }} sx={{ paddingTop: { xs: '10px!important', md: '0px!important' } }}>
        {(errors.terms || errors.privacy || errors.concession || errors.concession2) && (
          <Alert severity="error" sx={{ mb: 3, marginTop: '10px' }}>
            Bitte akzeptieren Sie unsere Bedingungen.
          </Alert>
        )}
      </Grid>
    </Box>
  );
}
