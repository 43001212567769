import { createContext, useReducer } from 'react';
import PropTypes from 'prop-types';
import qs from 'qs';
import { useSnackbar } from 'notistack';

// utils
import axios from '../utils/axios';
import useAuth from 'src/hooks/useAuth';

// ----------------------------------------------------------------------

const initialState = {
  thgs: {
    dashboard: { data: [], count: 0 },
    validated: { data: [], count: 0 },
    new: { data: [], count: 0 },
    unverified: { data: [], count: 0 },
    submitted: { data: [], count: 0 },
    sold: { data: [], count: 0 },
    readyForPayout: { data: [], count: 0 },
    paidOut: { data: [], count: 0 },
    rejected: { data: [], count: 0 },
    rejected_validation: { data: [], count: 0 },
    search: { data: [], count: 0 },
    rejected_uba: { data: [], count: 0 },
    archived: { data: [], count: 0 },
    completed: { data: [], count: 0 },
    invitesThg: { data: [], count: 0 },
    waitingForUba: { data: [], count: 0 },
  },
  thgsSearch: [],
  submissionReadyProcesses: { data: [], count: 0 },
  fundingReadyProcesses: { data: [], count: 0 },
  thgData: false,
  fleetClients: {
    date: new Date(),
    clients: [],
  },
  client: false,
  fleetClient: false,
  loading: false,
  loadingSearch: false,
  dashboardLoading: false,
  dashboardLoadingTS: false,
  charityLoading: false,
  charityLoadingTS: false,
  dashboardData: {
    revenue: 0,
    count: {
      new: 0,
      validated: 0,
      submitted: 0,
      sold: 0,
      paidOut: 0,
      readyForPayout: 0,
      rejected: 0,
      archived: 0,
      unverified: 0,
      waitingForUba: 0,
    },
    averageTillPayoutHours: 0,
    invites: {
      inviteCountAccepted: 0,
      inviteCountOpened: 0,
      percentageAccepted: 0,
      percentageOpened: 0,
      total: 0,
    },
  },
  dashboardDataTS: { timeSeriesCreatedAt: [], sum: 0 },
  charityDataTS: { timeSeriesCreatedAt: [] },
  userPartner: null,
  userApiKeys: null,
  payouts: { data: [], count: 0 },
  payins: { data: [], count: 0 },
  payoutProcesses: [],
  payinProcesses: [],
  claims: { data: [], count: 0 },
  teamUsers: { data: [], count: 0 },
  quotas: [],
  blacklist: { data: [], count: 0 },
  blogs: { data: [], count: 0 },
  subscriptions: { data: [], count: 0 },
  invitesThg: { data: [], count: 0 },
  partners: { data: [], count: 0 },
  submissions: { data: [], count: 0 },
  submission: { data: [], count: 0 },
  payout: { data: [], count: 0 },
  payin: { data: [], count: 0 },
  claim: { data: [], count: 0 },
  reloadDataTrigger: false,
  charityData: [],
};

const handlers = {
  RELOAD_DATA: (state, action) => {
    return {
      ...state,
      reloadDataTrigger: !state.reloadDataTrigger,
    };
  },
  RESET_THGS: (state, action) => {
    const { thgs } = action.payload;
    return {
      ...state,
      thgs: { data: [], count: 0 },
    };
  },
  GET_USER_PARTNER: (state, action) => {
    const { userPartner } = action.payload;
    return {
      ...state,
      userPartner,
    };
  },
  GET_CHARITY_DATA: (state, action) => {
    const { charityData } = action.payload;
    return {
      ...state,
      charityData,
    };
  },
  GET_SUBMISSIONREADY_PROCESSES: (state, action) => {
    const { submissionReadyProcesses } = action.payload;
    return {
      ...state,
      submissionReadyProcesses,
    };
  },
  GET_FUNDINGREADY_PROCESSES: (state, action) => {
    const { fundingReadyProcesses } = action.payload;
    return {
      ...state,
      fundingReadyProcesses,
    };
  },

  GET_USER_API_KEYS: (state, action) => {
    const { userApiKeys } = action.payload;
    return {
      ...state,
      userApiKeys,
    };
  },
  LOAD_THGS: (state, action) => {
    const { thgs, status } = action.payload;
    const thgsState = state.thgs;
    thgsState[status] = thgs;
    return {
      ...state,
      thgs: thgsState,
    };
  },
  LOAD_THGS_SEARCH: (state, action) => {
    const { thgsSearch } = action.payload;
    return {
      ...state,
      thgsSearch,
    };
  },

  LOAD_THG: (state, action) => {
    const { thgData } = action.payload;
    return {
      ...state,
      thgData,
    };
  },
  TRIGGER_IMAGE_RECOGNITION: (state, action) => {
    const { vehicle } = action.payload;
    const thgData = state.thgData;
    thgData['vehicle'] = vehicle;
    return {
      ...state,
      thgData,
    };
  },
  LOAD_PARTNERS: (state, action) => {
    const { partners } = action.payload;
    return {
      ...state,
      partners,
    };
  },
  LOAD_SUBMISSIONS: (state, action) => {
    const { submissions } = action.payload;
    return {
      ...state,
      submissions,
    };
  },
  LOAD_SUBMISSION: (state, action) => {
    const { submission } = action.payload;
    return {
      ...state,
      submission,
    };
  },
  LOAD_PAYOUT: (state, action) => {
    const { payout } = action.payload;
    return {
      ...state,
      payout,
    };
  },
  LOAD_PAYIN: (state, action) => {
    const { payin } = action.payload;
    return {
      ...state,
      payin,
    };
  },
  LOAD_CLAIM: (state, action) => {
    const { claim } = action.payload;
    return {
      ...state,
      claim,
    };
  },

  LOAD_FLEET_CLIENTS: (state, action) => {
    const { fleetClients } = action.payload;
    return {
      ...state,
      fleetClients,
    };
  },

  LOAD_FLEET_CLIENT: (state, action) => {
    const { fleetClient } = action.payload;
    return {
      ...state,
      fleetClient,
    };
  },

  LOAD_DASHBOARD_DATA: (state, action) => {
    const { dashboardData } = action.payload;
    return {
      ...state,
      dashboardData,
    };
  },

  LOAD_DASHBOARD_DATA_TS: (state, action) => {
    const { dashboardDataTS } = action.payload;
    return {
      ...state,
      dashboardDataTS,
    };
  },

  LOAD_CHARITY_DATA_TS: (state, action) => {
    const { charityDataTS } = action.payload;
    return {
      ...state,
      charityDataTS,
    };
  },

  START_LOADING: (state, action) => {
    return {
      ...state,
      loading: true,
    };
  },
  STOP_LOADING: (state, action) => {
    return {
      ...state,
      loading: false,
    };
  },
  START_LOADING_SEARCH: (state, action) => {
    return {
      ...state,
      loadingSearch: true,
      thgsSearch: [],
    };
  },
  STOP_LOADING_SEARCH: (state, action) => {
    return {
      ...state,
      loadingSearch: false,
    };
  },

  START_LOADING_DASHBOARD: (state, action) => {
    return {
      ...state,
      dashboardLoading: true,
    };
  },
  STOP_LOADING_DASHBOARD: (state, action) => {
    return {
      ...state,
      dashboardLoading: false,
    };
  },
  START_LOADING_CHARITY_TS: (state, action) => {
    return {
      ...state,
      charityLoadingTS: true,
    };
  },
  STOP_LOADING_CHARITY_TS: (state, action) => {
    return {
      ...state,
      charityLoadingTS: false,
    };
  },
  START_LOADING_CHARITY: (state, action) => {
    return {
      ...state,
      charityLoading: true,
    };
  },
  STOP_LOADING_CHARITY: (state, action) => {
    return {
      ...state,
      charityLoading: false,
    };
  },
  START_LOADING_DASHBOARD_TS: (state, action) => {
    return {
      ...state,
      dashboardLoadingTS: true,
    };
  },

  STOP_LOADING_DASHBOARD_TS: (state, action) => {
    return {
      ...state,
      dashboardLoadingTS: false,
    };
  },
  GET_INVITES_THG: (state, action) => {
    const { invitesThg } = action.payload;
    return {
      ...state,
      invitesThg,
    };
  },
  GET_COMMENTS: (state, action) => {
    const { comments } = action.payload;
    return {
      ...state,
      comments,
    };
  },
  RESET_THGS_SEARCH: (state, action) => {
    return {
      ...state,
      thgsSearch: [],
    };
  },
  GET_PAYOUT_PROCESSES: (state, action) => {
    const { payoutProcesses } = action.payload;
    return {
      ...state,
      thgsSearch: [],
      payoutProcesses,
    };
  },
  GET_PAYIN_PROCESSES: (state, action) => {
    const { payinProcesses } = action.payload;
    return {
      ...state,
      thgsSearch: [],
      payinProcesses,
    };
  },
  GET_PAYOUTS: (state, action) => {
    const { payouts } = action.payload;
    return {
      ...state,
      thgsSearch: [],
      payouts,
    };
  },
  GET_PAYINS: (state, action) => {
    const { payins } = action.payload;
    return {
      ...state,
      thgsSearch: [],
      payins,
    };
  },
  GET_CLAIMS: (state, action) => {
    const { claims } = action.payload;
    return {
      ...state,
      claims,
    };
  },
  GET_TEAM_USERS: (state, action) => {
    const { teamUsers } = action.payload;
    return {
      ...state,
      teamUsers,
    };
  },

  LOAD_BLACKLIST: (state, action) => {
    const { items } = action.payload;
    return {
      ...state,
      blacklist: items,
    };
  },
  LOAD_BLOGS: (state, action) => {
    const { items } = action.payload;

    return {
      ...state,
      blogs: items,
    };
  },
  LOAD_SUBSCRIPTIONS: (state, action) => {
    const { items } = action.payload;

    return {
      ...state,
      subscriptions: items,
    };
  },
  GET_QUOTAS: (state, action) => {
    const { quotas } = action.payload;
    return {
      ...state,
      quotas,
    };
  },
  SUBSCRIPTION_ACTIVATED: (state, action) => {
    const { item } = action.payload;
    const subscriptions = state.subscriptions;
    const newSubscriptionsData = state.subscriptions.data.map((subscription) =>
      subscription.email === item.email ? item : subscription
    );
    subscriptions['data'] = newSubscriptionsData;
    return {
      ...state,
      subscriptions: subscriptions,
    };
  },
};

const reducer = (state, action) => (handlers[action.type] ? handlers[action.type](state, action) : state);

const DataContext = createContext({
  ...initialState,
  editBlogById: () => Promise.resolve(),
  deleteBlogById: () => Promise.resolve(),
  createBlog: () => Promise.resolve(),
  getBlogs: () => Promise.resolve(),
  getSubscriptions: () => Promise.resolve(),
  deleteSubscription: () => Promise.resolve(),
  activateSubscription: () => Promise.resolve(),
  getThg: () => Promise.resolve(),
  getThgs: () => Promise.resolve(),
  getBlacklist: () => Promise.resolve(),
  getThgsSearch: () => Promise.resolve(),
  getUserPartner: () => Promise.resolve(),
  updateThg: () => Promise.resolve(),
  submitThgs: () => Promise.resolve(),
  archiveThg: () => Promise.resolve(),
  restoreThg: () => Promise.resolve(),
  extendLockThg: () => Promise.resolve(),
  rejectThg: () => Promise.resolve(),
  deleteThg: () => Promise.resolve(),
  deleteApiKey: () => Promise.resolve(),
  validateThg: () => Promise.resolve(),
  processPayout: () => Promise.resolve(),
  processPayin: () => Promise.resolve(),
  updateVehicle: () => Promise.resolve(),
  createVehicles: () => Promise.resolve(),
  getFleetClients: () => Promise.resolve(),
  updateClient: () => Promise.resolve(),
  getFleetClient: () => Promise.resolve(),
  createClient: () => Promise.resolve(),
  createPartner: () => Promise.resolve(),
  deleteClient: () => Promise.resolve(),
  deleteBlacklistItem: () => Promise.resolve(),
  deleteVehicle: () => Promise.resolve(),
  getUserApiKeys: () => Promise.resolve(),
  generateApiKey: () => Promise.resolve(),
  setProcessStatus: () => Promise.resolve(),
  createInviteThg: () => Promise.resolve(),
  inviteUserAccount: () => Promise.resolve(),
  resendInviteAccount: () => Promise.resolve(),
  getInvitesThg: () => Promise.resolve(),
  deleteInviteThg: () => Promise.resolve(),
  resetThgsSearch: () => Promise.resolve(),
  downloadSubmissionFile: () => Promise.resolve(),
  setUserDeactivated: () => Promise.resolve(),
  setUserViewRights: () => Promise.resolve(),
  setUbaStatus: () => Promise.resolve(),
  bankReceived: () => Promise.resolve(),
  getSubmissionIds: () => Promise.resolve(),
  getDashboardData: () => Promise.resolve(),
  getDashboardDataTS: () => Promise.resolve(),
  getCharityDataTS: () => Promise.resolve(),
  getExcelReport: () => Promise.resolve(),
  getAdminRevenueReport: () => Promise.resolve(),
  getComments: () => Promise.resolve(),
  postComment: () => Promise.resolve(),
  deleteComment: () => Promise.resolve(),
  getPayouts: () => Promise.resolve(),
  getPayins: () => Promise.resolve(),
  getPayout: () => Promise.resolve(),
  getPayin: () => Promise.resolve(),
  updateClaims: () => Promise.resolve(),
  updateClaim: () => Promise.resolve(),
  payoutClaim: () => Promise.resolve(),
  getClaims: () => Promise.resolve(),
  getTeamUsers: () => Promise.resolve(),
  getQuotas: () => Promise.resolve(),
  createManualClientClaim: () => Promise.resolve(),
  updatePartner: () => Promise.resolve(),
  createBlacklistItem: () => Promise.resolve(),
  getPartners: () => Promise.resolve(),
  setReloadData: () => Promise.resolve(),
  getCharityData: () => Promise.resolve(),
  payoutCharity: () => Promise.resolve(),
  payoutReclaim: () => Promise.resolve(),
  getReclaimPaybackFile: () => Promise.resolve(),
  reclaimBankReceived: () => Promise.resolve(),
  completedBankReceived: () => Promise.resolve(),
  rejectThgUba: () => Promise.resolve(),
  triggerImageRecognition: () => Promise.resolve(),
  getSubmissions: () => Promise.resolve(),
  getSubmission: () => Promise.resolve(),
  getSubmissionReadyProcesses: () => Promise.resolve(),
});

//----------------------------------------------------------------------------------

DataProvider.propTypes = {
  children: PropTypes.node,
};

function DataProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const { user, logout } = useAuth();
  const { enqueueSnackbar } = useSnackbar();

  const handleLogout = () => {
    enqueueSnackbar('Bitte melden Sie sich erneut an', {
      variant: 'error',
      anchorOrigin: {
        vertical: 'top',
        horizontal: 'center',
      },
    });

    logout();
  };

  const createBlacklistItem = async (type, value) => {
    try {
      dispatch({
        type: 'START_LOADING',
        payload: {},
      });

      const endpoint = `/v1/blacklist/create`;
      const response = await axios.post(endpoint, {
        type,
        value,
      });

      dispatch({
        type: 'STOP_LOADING',
        payload: {},
      });

      // get blacklist
      return response;
    } catch (err) {
      handleError(err);
      return err;
    }
  };

  const deleteBlogById = async (id) => {
    try {
      dispatch({
        type: 'START_LOADING',
        payload: {},
      });
      const endpoint = `/v1/blog/${id}`;

      const response = await axios.delete(endpoint);

      dispatch({
        type: 'STOP_LOADING',
        payload: {},
      });
      return response;
    } catch (err) {
      handleError(err);
    }
  };
  const deleteSubscription = async (id) => {
    try {
      dispatch({
        type: 'START_LOADING',
        payload: {},
      });
      const endpoint = `/v1/subscription/${id}`;

      const response = await axios.delete(endpoint);

      dispatch({
        type: 'STOP_LOADING',
        payload: {},
      });
      return response;
    } catch (err) {
      handleError(err);
    }
  };

  const editBlogById = async (data, id) => {
    try {
      dispatch({
        type: 'START_LOADING',
        payload: {},
      });
      const endpoint = `/v1/blog/${id}`;

      const response = await axios.post(endpoint, data, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      dispatch({
        type: 'STOP_LOADING',
        payload: {},
      });

      return response;
    } catch (err) {
      handleError(err);
    }
  };
  const activateBlogById = async (isActivated, id) => {
    try {
      dispatch({
        type: 'START_LOADING',
        payload: {},
      });
      const endpoint = `/v1/blog/${id}/activate`;

      const response = await axios.post(endpoint, { isActivated });

      dispatch({
        type: 'STOP_LOADING',
        payload: {},
      });

      return response;
    } catch (err) {
      handleError(err);
    }
  };

  const activateSubscription = async (id, isActivated) => {
    try {
      dispatch({
        type: 'START_LOADING',
        payload: {},
      });
      const endpoint = `/v1/subscription/${id}/activate`;

      const response = await axios.post(endpoint, { isActivated });
      dispatch({
        type: 'SUBSCRIPTION_ACTIVATED',
        payload: { item: response.data.result },
      });
      dispatch({
        type: 'STOP_LOADING',
        payload: {},
      });

      return response;
    } catch (err) {
      handleError(err);
    }
  };

  const createBlog = async (data) => {
    try {
      dispatch({
        type: 'START_LOADING',
        payload: {},
      });
      const endpoint = `/v1/blog`;

      const response = await axios.post(endpoint, data, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      dispatch({
        type: 'STOP_LOADING',
        payload: {},
      });
      return response;
    } catch (err) {
      handleError(err);
    }
  };

  const getBlogs = async (sortModel, page, limit) => {
    try {
      dispatch({
        type: 'START_LOADING',
        payload: {},
      });

      const endpoint = `/v1/blog/`;
      const response = await axios.get(endpoint, {
        params: {
          limit,
          page,
          sortModel,
        },
      });
      dispatch({
        type: 'LOAD_BLOGS',
        payload: {
          items: response.data,
        },
      });

      dispatch({
        type: 'STOP_LOADING',
        payload: {},
      });
    } catch (err) {
      handleError(err);
    }
  };
  const getSubmissions = async (limit, page, sortModel) => {
    try {
      dispatch({
        type: 'START_LOADING',
        payload: {},
      });

      const endpoint = `/v1/submission/`;
      const response = await axios.get(endpoint, {
        params: {
          limit,
          page,
          sortModel,
        },
      });
      dispatch({
        type: 'LOAD_SUBMISSIONS',
        payload: {
          submissions: response.data,
        },
      });

      dispatch({
        type: 'STOP_LOADING',
        payload: {},
      });
    } catch (err) {
      handleError(err);
    }
  };

  const getSubmission = async (limit, page, sortModel, searchString, submissionId) => {
    try {
      dispatch({
        type: 'START_LOADING',
        payload: {},
      });

      const endpoint = `/v1/submission/${submissionId}`;
      const response = await axios.get(endpoint, {
        params: {
          limit,
          page,
          sortModel,
          searchString: searchString,
        },
      });
      dispatch({
        type: 'LOAD_SUBMISSION',
        payload: {
          submission: response.data,
        },
      });

      dispatch({
        type: 'STOP_LOADING',
        payload: {},
      });
    } catch (err) {
      handleError(err);
    }
  };

  const getPayout = async (limit, page, sortModel, searchString, payoutId) => {
    try {
      dispatch({
        type: 'START_LOADING',
        payload: {},
      });

      const endpoint = `/v1/payout/detail/${payoutId}`;
      const response = await axios.get(endpoint, {
        params: {
          limit,
          page,
          sortModel,
          searchString: searchString,
        },
      });
      dispatch({
        type: 'LOAD_PAYOUT',
        payload: {
          payout: response.data,
        },
      });

      dispatch({
        type: 'STOP_LOADING',
        payload: {},
      });
    } catch (err) {
      handleError(err);
    }
  };
  const getPayin = async (limit, page, sortModel, searchString, payinId) => {
    try {
      dispatch({
        type: 'START_LOADING',
        payload: {},
      });

      const endpoint = `/v1/payin/detail/${payinId}`;
      const response = await axios.get(endpoint, {
        params: {
          limit,
          page,
          sortModel,
          searchString: searchString,
        },
      });
      dispatch({
        type: 'LOAD_PAYIN',
        payload: {
          payin: response.data,
        },
      });

      dispatch({
        type: 'STOP_LOADING',
        payload: {},
      });
    } catch (err) {
      handleError(err);
    }
  };

  const getClaim = async (limit, page, sortModel, searchString, claimId) => {
    try {
      dispatch({
        type: 'START_LOADING',
        payload: {},
      });

      const endpoint = `/v1/claim/detail/${claimId}`;
      const response = await axios.get(endpoint, {
        params: {
          limit,
          page,
          sortModel,
          searchString: searchString,
        },
      });
      dispatch({
        type: 'LOAD_CLAIM',
        payload: {
          claim: response.data,
        },
      });

      dispatch({
        type: 'STOP_LOADING',
        payload: {},
      });
    } catch (err) {
      handleError(err);
    }
  };

  const getSubscriptions = async (sortModel, page, limit) => {
    try {
      dispatch({
        type: 'START_LOADING',
        payload: {},
      });

      const endpoint = `/v1/subscription/`;
      const response = await axios.get(endpoint, {
        params: {
          limit,
          page,
          sortModel,
        },
      });
      dispatch({
        type: 'LOAD_SUBSCRIPTIONS',
        payload: {
          items: response.data,
        },
      });

      dispatch({
        type: 'STOP_LOADING',
        payload: {},
      });
    } catch (err) {
      handleError(err);
    }
  };

  const getBlacklist = async (limit, page, sortModel, status) => {
    try {
      dispatch({
        type: 'START_LOADING',
        payload: {},
      });

      const endpoint = `/v1/blacklist/`;
      const response = await axios.get(endpoint, {
        params: {
          limit,
          page,
          sortModel,
        },
      });
      dispatch({
        type: 'LOAD_BLACKLIST',
        payload: {
          items: response.data,
          status,
        },
      });

      dispatch({
        type: 'STOP_LOADING',
        payload: {},
      });
    } catch (err) {
      handleError(err);
    }
  };

  const getPartners = async (limit, page, sortModel) => {
    try {
      dispatch({
        type: 'START_LOADING',
        payload: {},
      });

      const endpoint = `/v1/partner/`;
      const response = await axios.get(endpoint, {
        params: {
          limit: limit,
          page: page,
          sortModel: sortModel,
        },
      });

      dispatch({
        type: 'LOAD_PARTNERS',
        payload: {
          partners: response.data,
        },
      });

      dispatch({
        type: 'STOP_LOADING',
        payload: {},
      });
    } catch (err) {
      handleError(err);
    }
  };
  const getCharityData = async (startDate, endDate) => {
    try {
      dispatch({
        type: 'START_LOADING_CHARITY',
        payload: {},
      });

      const endpoint = `/v1/charity`;
      const response = await axios.get(endpoint, {
        params: {
          startDate: startDate,
          endDate: endDate,
        },
      });

      dispatch({
        type: 'GET_CHARITY_DATA',
        payload: {
          charityData: response.data,
        },
      });

      dispatch({
        type: 'STOP_LOADING_CHARITY',
        payload: {},
      });
    } catch (err) {
      handleError(err);
    }
  };
  const payoutCharity = async (charityId) => {
    try {
      dispatch({
        type: 'START_LOADING',
        payload: {},
      });
      const data = { charityId };
      const endpoint = `/v1/charity`;
      const response = await axios.post(endpoint, data);
      dispatch({
        type: 'STOP_LOADING',
        payload: {},
      });
      return response;
    } catch (err) {
      handleError(err);
    }
  };

  const getThgs = async (
    status,
    limit,
    page,
    startDate,
    endDate,
    searchString,
    sortModel,
    year,
    instantPayout,
    filter,
    onlyCharity,
    submissionPartner
  ) => {
    try {
      dispatch({
        type: 'START_LOADING',
        payload: {},
      });

      const endpoint = `/v1/thg/`;
      const response = await axios.get(endpoint, {
        params: {
          limit: limit,
          page: page,
          status: status,
          startDate: startDate,
          endDate: endDate,
          searchString: searchString,
          sortModel: sortModel,
          year: year,
          instantPayout,
          filter,
          onlyCharity,
          submissionPartner,
        },
      });
      dispatch({
        type: 'LOAD_THGS',
        payload: {
          thgs: response.data,
          status,
        },
      });

      dispatch({
        type: 'STOP_LOADING',
        payload: {},
      });
    } catch (err) {
      handleError(err);
    }
  };
  const getExcelReport = async (startDate, endDate, filter) => {
    try {
      dispatch({
        type: 'START_LOADING',
        payload: {},
      });

      const endpoint = `/v1/thg/excelReport`;
      const response = await axios.get(endpoint, {
        responseType: 'blob',
        params: {
          startDate: startDate,
          endDate: endDate,
          filter,
        },
      });

      dispatch({
        type: 'STOP_LOADING',
        payload: {},
      });

      return response;
    } catch (err) {
      handleError(err);
    }
  };
  const getAdminRevenueReport = async () => {
    try {
      dispatch({
        type: 'START_LOADING',
        payload: {},
      });

      const endpoint = `/v1/thg/adminRevenueReport`;
      const response = await axios.get(endpoint, {
        responseType: 'blob',
        params: {},
      });

      dispatch({
        type: 'STOP_LOADING',
        payload: {},
      });

      return response;
    } catch (err) {
      handleError(err);
    }
  };
  const getThgsSearch = async (limit, searchString) => {
    try {
      dispatch({
        type: 'START_LOADING_SEARCH',
        payload: {},
      });

      const endpoint = `/v1/thg/search`;
      const response = await axios.get(endpoint, {
        params: {
          limit: limit,
          searchString: searchString,
        },
      });
      dispatch({
        type: 'LOAD_THGS_SEARCH',
        payload: {
          thgsSearch: response.data.data,
        },
      });

      dispatch({
        type: 'STOP_LOADING_SEARCH',
        payload: {},
      });
    } catch (err) {
      handleError(err);
    }
  };
  const getDashboardData = async (startDate, endDate, filter) => {
    try {
      dispatch({
        type: 'START_LOADING_DASHBOARD',
        payload: {},
      });
      const endpoint = `/v1/thg/dashboard`;
      const response = await axios.get(endpoint, {
        params: {
          startDate: startDate,
          endDate: endDate,
          filter: JSON.stringify(filter),
        },
      });
      dispatch({
        type: 'LOAD_DASHBOARD_DATA',
        payload: {
          dashboardData: response.data,
        },
      });

      dispatch({
        type: 'STOP_LOADING_DASHBOARD',
        payload: {},
      });
    } catch (err) {
      handleError(err);
    }
  };

  const getDashboardDataTS = async (startDate, endDate, filter) => {
    try {
      dispatch({
        type: 'START_LOADING_DASHBOARD_TS',
        payload: {},
      });
      const endpoint = `/v1/thg/dashboardTS`;
      const response = await axios.get(endpoint, {
        params: {
          filter: JSON.stringify(filter),
          startDate: startDate,
          endDate: endDate,
        },
      });
      dispatch({
        type: 'LOAD_DASHBOARD_DATA_TS',
        payload: {
          dashboardDataTS: response.data,
        },
      });

      dispatch({
        type: 'STOP_LOADING_DASHBOARD_TS',
        payload: {},
      });
    } catch (err) {
      handleError(err);
    }
  };

  const getCharityDataTS = async (startDate, endDate) => {
    try {
      dispatch({
        type: 'START_LOADING_CHARITY_TS',
        payload: {},
      });

      const endpoint = `/v1/charity/getCharityDataTS`;
      const response = await axios.get(endpoint, {
        params: {
          startDate: startDate,
          endDate: endDate,
        },
      });
      dispatch({
        type: 'LOAD_CHARITY_DATA_TS',
        payload: {
          charityDataTS: response.data,
        },
      });
      dispatch({
        type: 'STOP_LOADING_CHARITY_TS',
        payload: {},
      });

      return response;
    } catch (err) {
      handleError(err);
    }
  };

  const getUserPartner = async () => {
    try {
      dispatch({
        type: 'START_LOADING',
        payload: {},
      });
      const endpoint = `/v1/users/partner`;
      const response = await axios.get(endpoint, {
        params: {},
      });
      dispatch({
        type: 'GET_USER_PARTNER',
        payload: {
          userPartner: response.data,
        },
      });

      dispatch({
        type: 'STOP_LOADING',
        payload: {},
      });
    } catch (err) {
      handleError(err);
    }
  };
  const getUserApiKeys = async (userId) => {
    try {
      dispatch({
        type: 'START_LOADING',
        payload: {},
      });
      const endpoint = `/v1/users/api-key`;
      const response = await axios.get(endpoint, {
        params: {
          userId,
        },
      });
      dispatch({
        type: 'GET_USER_API_KEYS',
        payload: {
          userApiKeys: response.data,
        },
      });

      dispatch({
        type: 'STOP_LOADING',
        payload: {},
      });
    } catch (err) {
      handleError(err);
    }
  };
  const getInvitesThg = async (limit, page, sortModel) => {
    try {
      dispatch({
        type: 'START_LOADING',
        payload: {},
      });
      const endpoint = `/v1/users/invite/thg`;
      const response = await axios.get(endpoint, {
        params: {
          limit: limit,
          page: page,
          sortModel: sortModel,
        },
      });
      dispatch({
        type: 'GET_INVITES_THG',
        payload: {
          invitesThg: response.data,
        },
      });

      dispatch({
        type: 'STOP_LOADING',
        payload: {},
      });
    } catch (err) {
      handleError(err);
    }
  };

  const updateThg = async (thgId, data) => {
    dispatch({
      type: 'START_LOADING',
      payload: {},
    });
    try {
      const endpoint = `/v1/thg/${thgId}`;
      const response = await axios.patch(endpoint, data);

      // getThgs();
      dispatch({
        type: 'STOP_LOADING',
        payload: {},
      });
      return response;
    } catch (err) {
      handleError(err);
      return err;
    }
  };

  const updateVehicle = async (vehicleId, data) => {
    try {
      dispatch({
        type: 'START_LOADING',
        payload: {},
      });
      const endpoint = `/v1/vehicle/${vehicleId}`;
      const response = await axios.patch(endpoint, data);
      if (response.data.isFleet) {
        getFleetClients();
      }

      dispatch({
        type: 'STOP_LOADING',
        payload: {},
      });
      // getThgs();
      return response;
    } catch (err) {
      handleError(err);
    }
  };

  const getFleetClient = async (fleetId) => {
    try {
      dispatch({
        type: 'START_LOADING',
        payload: {},
      });
      const endpoint = `/v1/client/fleet/${fleetId}`;
      const response = await axios.get(endpoint);
      dispatch({
        type: 'LOAD_FLEET_CLIENT',
        payload: {
          fleetClient: response.data,
        },
      });

      dispatch({
        type: 'STOP_LOADING',
        payload: {},
      });
    } catch (err) {
      handleError(err);
    }
  };

  const getPayoutTransactions = async (payoutId) => {
    try {
      dispatch({
        type: 'START_LOADING',
        payload: {},
      });
      const endpoint = `/v1/thg/getPayoutTransactions/${payoutId}`;
      const response = await axios.get(endpoint);

      dispatch({
        type: 'STOP_LOADING',
        payload: {},
      });

      return response.data;
    } catch (err) {
      handleError(err);
    }
  };

  const getPayoutFile = async (payoutId) => {
    try {
      dispatch({
        type: 'START_LOADING',
        payload: {},
      });
      const endpoint = `/v1/payout/getPayoutFile/${payoutId}`;
      const response = await axios.get(endpoint, {
        headers: {
          'Content-Type': 'text/xml',
        },
      });

      dispatch({
        type: 'STOP_LOADING',
        payload: {},
      });
      return response.data;
    } catch (err) {
      handleError(err);
      return err;
    }
  };

  const getReclaimPaybackFile = async (selectedClaimReceiptId) => {
    try {
      dispatch({
        type: 'START_LOADING',
        payload: {},
      });
      const endpoint = `/v1/claim/getReclaimPaybackFile/${selectedClaimReceiptId}`;
      const response = await axios.get(endpoint, {
        headers: {
          'Content-Type': 'text/xml',
        },
      });

      dispatch({
        type: 'STOP_LOADING',
        payload: {},
      });
      return response.data;
    } catch (err) {
      handleError(err);
      return err;
    }
  };

  const createClient = async (data) => {
    try {
      dispatch({
        type: 'START_LOADING',
        payload: {},
      });
      const endpoint = `/v1/client`;
      const response = await axios.post(endpoint, data);
      dispatch({
        type: 'STOP_LOADING',
        payload: {},
      });

      //  refresh with new client- opimize with adding response to store
      getFleetClients();
      return response;
    } catch (err) {
      handleError(err);
    }
  };

  const createPartner = async (data) => {
    try {
      dispatch({
        type: 'START_LOADING',
        payload: {},
      });

      const endpoint = `/v1/partner/create`;
      const response = await axios.post(endpoint, data, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      dispatch({
        type: 'STOP_LOADING',
        payload: {},
      });
      return response;
    } catch (err) {
      handleError(err);
      return err;
    }
  };

  const createInviteThg = async (data) => {
    try {
      dispatch({
        type: 'START_LOADING',
        payload: {},
      });
      const endpoint = `/v1/users/invite/thg`;
      const response = await axios.post(endpoint, data);
      dispatch({
        type: 'STOP_LOADING',
        payload: {},
      });
      return response;
    } catch (err) {
      handleError(err);
    }
  };

  const inviteUserAccount = async (data) => {
    try {
      dispatch({
        type: 'START_LOADING',
        payload: {},
      });
      const endpoint = `/v1/users/invite/account`;
      const response = await axios.post(endpoint, data);
      dispatch({
        type: 'STOP_LOADING',
        payload: {},
      });
      getUserPartner();
      return response;
    } catch (err) {
      if (err.message && err.message.includes('email_1 dup key')) {
        enqueueSnackbar('Für diese E-Mail-Adresse ist bereits ein Account registriert', {
          variant: 'error',
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'center',
          },
        });
        dispatch({
          type: 'STOP_LOADING',
          payload: {},
        });
      } else {
        handleError(err);
      }
    }
  };

  const resendInviteAccount = async (data) => {
    try {
      dispatch({
        type: 'START_LOADING',
        payload: {},
      });
      const endpoint = `/v1/users/invite/account/resend`;
      const response = await axios.post(endpoint, data);
      dispatch({
        type: 'STOP_LOADING',
        payload: {},
      });
      getUserPartner();
      return response;
    } catch (err) {
      handleError(err);
    }
  };

  const updateClient = async (clientId, data) => {
    try {
      dispatch({
        type: 'START_LOADING',
        payload: {},
      });
      const endpoint = `/v1/client/${clientId}`;
      const response = await axios.patch(endpoint, data);
      dispatch({
        type: 'LOAD_CLIENT',
        payload: {
          client: response.data,
        },
      });
      dispatch({
        type: 'STOP_LOADING',
        payload: {},
      });
      // getThgs();
      return response;
    } catch (err) {
      handleError(err);
    }
  };

  const deleteBlacklistItem = async (value) => {
    try {
      dispatch({
        type: 'START_LOADING',
        payload: {},
      });
      const endpoint = `/v1/blacklist/${value}`;
      const response = await axios.delete(endpoint);

      await getBlacklist();
      dispatch({
        type: 'STOP_LOADING',
        payload: {},
      });
    } catch (err) {
      handleError(err);
    }
  };

  const deleteClient = async (clientId) => {
    try {
      dispatch({
        type: 'START_LOADING',
        payload: {},
      });
      const endpoint = `/v1/client/${clientId}`;
      const response = await axios.delete(endpoint);

      await getFleetClients();
      dispatch({
        type: 'STOP_LOADING',
        payload: {},
      });
    } catch (err) {
      handleError(err);
    }
  };
  const deleteApiKey = async (id, userId) => {
    try {
      dispatch({
        type: 'START_LOADING',
        payload: {},
      });
      const endpoint = `/v1/users/api-key/${id}`;
      const response = await axios.delete(endpoint);

      await getUserApiKeys(userId);
      dispatch({
        type: 'STOP_LOADING',
        payload: {},
      });
      return response;
    } catch (err) {
      handleError(err);
    }
  };
  const deleteInviteThg = async (inviteId) => {
    try {
      dispatch({
        type: 'START_LOADING',
        payload: {},
      });
      const endpoint = `/v1/users/invite/thg/${inviteId}`;
      const response = await axios.delete(endpoint);

      dispatch({
        type: 'STOP_LOADING',
        payload: {},
      });
      return response;
    } catch (err) {
      handleError(err);
    }
  };

  const validateThg = async (thgId, data) => {
    try {
      dispatch({
        type: 'START_LOADING',
        payload: {},
      });
      const endpoint = `/v1/thg/validate/${thgId}`;
      const response = await axios.post(endpoint, data);

      dispatch({
        type: 'STOP_LOADING',
        payload: {},
      });
      return response.data;
    } catch (err) {
      handleError(err);
    }
  };

  const submitThgs = async () => {
    try {
      dispatch({
        type: 'START_LOADING',
        payload: {},
      });
      const endpoint = `/v1/thg/submit`;
      const response = await axios.post(endpoint);

      dispatch({
        type: 'STOP_LOADING',
      });
      return response;
    } catch (err) {
      handleError(err);
    }
  };

  const processPayout = async (payoutType, payoutId) => {
    try {
      dispatch({
        type: 'START_LOADING',
        payload: {},
      });
      const endpoint = `/v1/payout/process/${payoutType}`;
      const response = await axios.post(endpoint, { payoutId });

      dispatch({
        type: 'STOP_LOADING',
        payload: {},
      });
      return response;
    } catch (err) {
      handleError(err);
    }
  };

  const processPayin = async (payinType, payinId) => {
    try {
      dispatch({
        type: 'START_LOADING',
        payload: {},
      });
      const endpoint = `/v1/payin/process/${payinType}`;
      const response = await axios.post(endpoint, { payinId });

      dispatch({
        type: 'STOP_LOADING',
        payload: {},
      });
      return response;
    } catch (err) {
      handleError(err);
    }
  };

  const archiveThg = async (thgId) => {
    try {
      dispatch({
        type: 'START_LOADING',
        payload: {},
      });
      const endpoint = `/v1/thg/archive/${thgId}`;
      const response = await axios.get(endpoint);

      dispatch({
        type: 'STOP_LOADING',
        payload: {},
      });
    } catch (err) {
      handleError(err);
    }
  };
  const setReloadData = async () => {
    try {
      dispatch({
        type: 'RELOAD_DATA',
        payload: {},
      });
    } catch (err) {
      handleError(err);
    }
  };

  const restoreThg = async (thgId) => {
    try {
      dispatch({
        type: 'START_LOADING',
        payload: {},
      });
      const endpoint = `/v1/thg/restore/${thgId}`;
      const response = await axios.get(endpoint);

      dispatch({
        type: 'STOP_LOADING',
        payload: {},
      });
    } catch (err) {
      handleError(err);
    }
  };
  const extendLockThg = async (thgId) => {
    try {
      dispatch({
        type: 'START_LOADING',
        payload: {},
      });
      const endpoint = `/v1/thg/extendLock/${thgId}`;
      const response = await axios.get(endpoint);

      dispatch({
        type: 'STOP_LOADING',
        payload: {},
      });
    } catch (err) {
      handleError(err);
    }
  };
  const setProcessStatus = async (thgId, status) => {
    try {
      dispatch({
        type: 'START_LOADING',
        payload: {},
      });
      const endpoint = `/v1/thg/setProcessStatus/${thgId}`;
      const response = await axios.get(endpoint, {
        params: {
          status: status,
        },
      });
      dispatch({
        type: 'STOP_LOADING',
        payload: {},
      });
    } catch (err) {
      handleError(err);
    }
  };

  const deleteThg = async (thgId) => {
    try {
      dispatch({
        type: 'START_LOADING',
        payload: {},
      });
      const endpoint = `/v1/thg/${thgId}`;
      const response = await axios.delete(endpoint);

      dispatch({
        type: 'STOP_LOADING',
        payload: {},
      });
      return response;
    } catch (err) {
      handleError(err);
    }
  };
  const getThg = async (thgId) => {
    try {
      dispatch({
        type: 'START_LOADING',
        payload: {},
      });
      const endpoint = `/v1/thg/${thgId}`;
      const response = await axios.get(endpoint);

      dispatch({
        type: 'LOAD_THG',
        payload: {
          thgData: response.data,
        },
      });
      getComments(thgId);
      dispatch({
        type: 'STOP_LOADING',
        payload: {},
      });
    } catch (err) {
      handleError(err);
    }
  };

  const getFleetClients = async () => {
    try {
      dispatch({
        type: 'START_LOADING',
        payload: {},
      });
      const endpoint = `/v1/client/fleet`;
      const response = await axios.get(endpoint);

      dispatch({
        type: 'LOAD_FLEET_CLIENTS',
        payload: {
          fleetClients: response.data,
        },
      });
      dispatch({
        type: 'STOP_LOADING',
        payload: {},
      });
    } catch (err) {
      handleError(err);
    }
  };

  const createPayoutClient = async (selectedSubmissionId) => {
    try {
      dispatch({
        type: 'START_LOADING',
        payload: {},
      });
      const endpoint = `/v1/thg/createPayoutClient/${selectedSubmissionId}`;
      const response = await axios.get(endpoint);

      dispatch({
        type: 'STOP_LOADING',
        payload: {},
      });
      return response;
    } catch (err) {
      handleError(err);
    }
  };

  const getSubmissionIds = async (year) => {
    try {
      dispatch({
        type: 'START_LOADING',
        payload: {},
      });
      const endpoint = `/v1/thg/submissionIds/${year}`;
      const response = await axios.get(endpoint);

      dispatch({
        type: 'STOP_LOADING',
        payload: {},
      });
      return response;
    } catch (err) {
      handleError(err);
    }
  };

  const createVehicles = async (data) => {
    try {
      dispatch({
        type: 'START_LOADING',
        payload: {},
      });
      const endpoint = `/v1/vehicle/fleet/`;
      const response = await axios.post(endpoint, data);
      dispatch({
        type: 'STOP_LOADING',
        payload: {},
      });

      //  refresh with new client- opimize with adding response to store
      getFleetClients();
      return response;
    } catch (err) {
      handleError(err);
    }
  };
  const generateApiKey = async (userId) => {
    try {
      dispatch({
        type: 'START_LOADING',
        payload: {},
      });
      const endpoint = `/v1/users/api-key/`;
      const response = await axios.post(endpoint, { userId });
      dispatch({
        type: 'STOP_LOADING',
        payload: {},
      });

      //  refresh with new client- opimize with adding response to store
      getUserApiKeys(userId);
      return response;
    } catch (err) {
      handleError(err);
    }
  };
  const rejectValidationThg = async (thgId, rejectionReason) => {
    try {
      dispatch({
        type: 'START_LOADING',
        payload: {},
      });
      const data = { rejectionReason };

      const endpoint = `/v1/thg/rejectValidation/${thgId}`;
      const response = await axios.post(endpoint, data);

      dispatch({
        type: 'STOP_LOADING',
        payload: {},
      });

      //  refresh with new client- opimize with adding response to store
      return response.data;
    } catch (err) {
      handleError(err);
    }
  };

  const deleteVehicle = async (vehicleId) => {
    try {
      dispatch({
        type: 'START_LOADING',
        payload: {},
      });
      const endpoint = `/v1/vehicle/${vehicleId}`;
      const response = await axios.delete(endpoint);
      dispatch({
        type: 'STOP_LOADING',
        payload: {},
      });

      //  refresh with new client- opimize with adding response to store
      getFleetClients();
      return response;
    } catch (err) {
      handleError(err);
    }
  };

  const submitFleetVehicles = async (data) => {
    try {
      dispatch({
        type: 'START_LOADING',
        payload: {},
      });
      const endpoint = `/v1/vehicle/fleet/submit`;
      const response = await axios.post(endpoint, data);
      dispatch({
        type: 'STOP_LOADING',
        payload: {},
      });

      //  refresh with new client- opimize with adding response to store
      getFleetClients();

      return response;
    } catch (err) {
      dispatch({
        type: 'STOP_LOADING',
        payload: {},
      });
      handleError(err);
    }
  };
  const rotateImage = async (filename, direction) => {
    try {
      dispatch({
        type: 'START_LOADING',
        payload: {},
      });
      const endpoint = `/v1/image/rotate/${filename}`;
      const response = await axios.get(endpoint, {
        params: {
          direction: direction,
        },
      });
      dispatch({
        type: 'STOP_LOADING',
        payload: {},
      });

      return response;
    } catch (err) {
      dispatch({
        type: 'STOP_LOADING',
        payload: {},
      });
      handleError(err);
    }
  };
  const validateNext = async (year) => {
    try {
      dispatch({
        type: 'START_LOADING',
        payload: {},
      });
      const endpoint = `/v1/thg/validate/next/${year}`;
      const response = await axios.get(endpoint);
      dispatch({
        type: 'STOP_LOADING',
        payload: {},
      });

      return response.data;
    } catch (err) {
      dispatch({
        type: 'STOP_LOADING',
        payload: {},
      });
      handleError(err);
    }
  };

  const changePassword = async (oldPassword, newPassword) => {
    try {
      dispatch({
        type: 'START_LOADING',
        payload: {},
      });
      const endpoint = `/v1/users/change-password`;
      const response = await axios.post(endpoint, { password: newPassword, oldPassword });
      dispatch({
        type: 'STOP_LOADING',
        payload: {},
      });
      if (response && response.status === 200) {
        enqueueSnackbar('Passwort erfolgreich geändert', {
          variant: 'success',
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'center',
          },
        });
      }
      return response;
    } catch (err) {
      if (err.code === 406) {
        enqueueSnackbar('Das aktuelle Passwort ist nicht korrekt', {
          variant: 'error',
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'center',
          },
        });
        dispatch({
          type: 'STOP_LOADING',
          payload: {},
        });
      } else {
        handleError(err);
      }
    }
  };

  const handleError = (error) => {
    console.error(error);
    if (error.code && error.code === 401) {
      handleLogout();
    } else {
      enqueueSnackbar('Es ist ein Fehler aufgetreten. Versuchen Sie bitte Die Seite neu zu laden.', {
        variant: 'error',
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'center',
        },
      });
      dispatch({
        type: 'STOP_LOADING',
        payload: {},
      });
    }
  };

  const resetThgsSearch = () => {
    dispatch({
      type: 'RESET_THGS_SEARCH',
      payload: {},
    });
  };

  const downloadSubmissionFile = async (processId) => {
    try {
      dispatch({
        type: 'START_LOADING',
        payload: {},
      });
      const endpoint = `/v1/thg/submissionFile/${processId}`;
      const response = await axios.get(endpoint);
      dispatch({
        type: 'STOP_LOADING',
        payload: {},
      });

      return response.data;
    } catch (err) {
      dispatch({
        type: 'STOP_LOADING',
        payload: {},
      });
      handleError(err);
    }
  };

  const updateClaims = async (claimReceiptId, data) => {
    try {
      dispatch({
        type: 'START_LOADING',
        payload: {},
      });
      const endpoint = `/v1/claim/update/${claimReceiptId}`;
      const response = await axios.post(endpoint, data);
      dispatch({
        type: 'STOP_LOADING',
        payload: {},
      });

      return response;
    } catch (err) {
      dispatch({
        type: 'STOP_LOADING',
        payload: {},
      });
      handleError(err);
    }
  };
  const updateClaim = async (claimReceiptId, data) => {
    try {
      dispatch({
        type: 'START_LOADING',
        payload: {},
      });
      const endpoint = `/v1/claim/update/single/${claimReceiptId}`;
      const response = await axios.post(endpoint, data);
      dispatch({
        type: 'STOP_LOADING',
        payload: {},
      });

      return response;
    } catch (err) {
      dispatch({
        type: 'STOP_LOADING',
        payload: {},
      });
      handleError(err);
    }
  };
  const payoutClaim = async (claimReceiptId) => {
    try {
      dispatch({
        type: 'START_LOADING',
        payload: {},
      });
      const endpoint = `/v1/claim/payout/${claimReceiptId}`;
      const response = await axios.post(endpoint);
      dispatch({
        type: 'STOP_LOADING',
        payload: {},
      });

      return response;
    } catch (err) {
      dispatch({
        type: 'STOP_LOADING',
        payload: {},
      });
      handleError(err);
    }
  };

  const setUserDeactivated = async (userId, deactivated) => {
    try {
      dispatch({
        type: 'START_LOADING',
        payload: {},
      });
      const endpoint = `/v1/users/deactivate/${userId}`;
      const response = await axios.post(endpoint, { deactivated });
      dispatch({
        type: 'STOP_LOADING',
        payload: {},
      });
      getUserPartner();
      return response;
    } catch (err) {
      dispatch({
        type: 'STOP_LOADING',
        payload: {},
      });
      handleError(err);
    }
  };

  const setUserViewRights = async (userId, allowedViews) => {
    try {
      dispatch({
        type: 'START_LOADING',
        payload: {},
      });
      const endpoint = `/v1/users/viewRights/${userId}`;
      const response = await axios.post(endpoint, { allowedViews });
      dispatch({
        type: 'STOP_LOADING',
        payload: {},
      });
      getUserPartner();
      return response;
    } catch (err) {
      dispatch({
        type: 'STOP_LOADING',
        payload: {},
      });
      handleError(err);
    }
  };

  const setUbaStatus = async (processId, status) => {
    try {
      dispatch({
        type: 'START_LOADING',
        payload: {},
      });
      const endpoint = `/v1/thg/trader/uba/${processId}`;
      const response = await axios.post(endpoint, { status });
      dispatch({
        type: 'STOP_LOADING',
        payload: {},
      });
      getUserPartner();
      return response;
    } catch (err) {
      dispatch({
        type: 'STOP_LOADING',
        payload: {},
      });
      handleError(err);
    }
  };

  const postComment = async (thgId, comment) => {
    try {
      dispatch({
        type: 'START_LOADING',
        payload: {},
      });
      const endpoint = `/v1/thg/comment/${thgId}`;
      const response = await axios.post(endpoint, { comment });
      dispatch({
        type: 'STOP_LOADING',
        payload: {},
      });
      return response;
    } catch (err) {
      dispatch({
        type: 'STOP_LOADING',
        payload: {},
      });
      handleError(err);
    }
  };

  const rejectThgUba = async (processId, feedbackId) => {
    try {
      dispatch({
        type: 'START_LOADING',
        payload: {},
      });
      const endpoint = `/v1/thg/trader/uba/${processId}`;
      const data = {
        status: 'rejected',
        message: '',
        feedbackId: feedbackId,
      };

      const response = await axios.post(endpoint, data);
      dispatch({
        type: 'STOP_LOADING',
        payload: {},
      });
      return response;
    } catch (err) {
      dispatch({
        type: 'STOP_LOADING',
        payload: {},
      });
      handleError(err);
    }
  };

  const getComments = async (thgId) => {
    try {
      dispatch({
        type: 'START_LOADING',
        payload: {},
      });
      const endpoint = `/v1/thg/comment/${thgId}`;
      const response = await axios.get(endpoint);
      dispatch({
        type: 'STOP_LOADING',
        payload: {},
      });

      dispatch({
        type: 'GET_COMMENTS',
        payload: { comments: response.data },
      });
      return response.data;
    } catch (err) {
      dispatch({
        type: 'STOP_LOADING',
        payload: {},
      });
      handleError(err);
    }
  };
  const getClaims = async (claimType, claimStatus, limit, page, sortModel, searchString) => {
    try {
      dispatch({
        type: 'START_LOADING',
        payload: {},
      });
      const endpoint = `/v1/claim/getClaims/${claimType}/${claimStatus}/`;
      const response = await axios.get(endpoint, {
        params: {
          limit: limit,
          page: page,
          sortModel: sortModel,
          searchString,
        },
      });
      dispatch({
        type: 'STOP_LOADING',
        payload: {},
      });

      dispatch({
        type: 'GET_CLAIMS',
        payload: { claims: response.data },
      });
    } catch (err) {
      dispatch({
        type: 'STOP_LOADING',
        payload: {},
      });
      handleError(err);
    }
  };

  const getPayouts = async (status, limit, page, sortModel) => {
    try {
      dispatch({
        type: 'START_LOADING',
        payload: {},
      });
      const endpoint = `/v1/payout/${status}`;
      const response = await axios.get(endpoint, {
        params: {
          limit: limit,
          page: page,
          sortModel: sortModel,
        },
      });
      dispatch({
        type: 'STOP_LOADING',
        payload: {},
      });

      dispatch({
        type: 'GET_PAYOUTS',
        payload: { payouts: response.data },
      });
      return response;
    } catch (err) {
      dispatch({
        type: 'STOP_LOADING',
        payload: {},
      });
      handleError(err);
    }
  };

  const getPayins = async (status, limit, page, sortModel) => {
    try {
      dispatch({
        type: 'START_LOADING',
        payload: {},
      });
      const endpoint = `/v1/payin/${status}`;
      const response = await axios.get(endpoint, {
        params: {
          limit: limit,
          page: page,
          sortModel: sortModel,
        },
      });
      dispatch({
        type: 'STOP_LOADING',
        payload: {},
      });

      dispatch({
        type: 'GET_PAYINS',
        payload: { payins: response.data },
      });
      return response;
    } catch (err) {
      dispatch({
        type: 'STOP_LOADING',
        payload: {},
      });
      handleError(err);
    }
  };

  const getTeamUsers = async (limit, page, sortModel) => {
    try {
      dispatch({
        type: 'START_LOADING',
        payload: {},
      });
      const endpoint = `/v1/users/team/users`;
      const response = await axios.get(endpoint, {
        params: {
          limit: limit,
          page: page,
          sortModel: sortModel,
        },
      });
      dispatch({
        type: 'STOP_LOADING',
        payload: {},
      });

      dispatch({
        type: 'GET_TEAM_USERS',
        payload: { teamUsers: response.data },
      });
      return response;
    } catch (err) {
      dispatch({
        type: 'STOP_LOADING',
        payload: {},
      });
      handleError(err);
    }
  };

  const deleteComment = async (commentId) => {
    try {
      dispatch({
        type: 'START_LOADING',
        payload: {},
      });
      const endpoint = `/v1/thg/comment/${commentId}`;
      const response = await axios.delete(endpoint);
      dispatch({
        type: 'STOP_LOADING',
        payload: {},
      });

      return response;
    } catch (err) {
      dispatch({
        type: 'STOP_LOADING',
        payload: {},
      });
      handleError(err);
    }
  };

  const getQuotas = async () => {
    try {
      dispatch({
        type: 'START_LOADING',
        payload: {},
      });
      const endpoint = `/v1/users/quotas`;
      const response = await axios.get(endpoint);
      dispatch({
        type: 'STOP_LOADING',
        payload: {},
      });
      dispatch({
        type: 'GET_QUOTAS',
        payload: { quotas: response.data },
      });
      return response.data;
    } catch (err) {
      dispatch({
        type: 'STOP_LOADING',
        payload: {},
      });
      handleError(err);
    }
  };

  const getSubmissionReadyProcesses = async (limit, page, sortModel) => {
    try {
      dispatch({
        type: 'START_LOADING',
        payload: {},
      });
      const endpoint = `/v1/submission/getSubmissionReadyProcesses`;
      const response = await axios.get(endpoint, {
        params: {
          limit: limit,
          page: page,
          sortModel: sortModel,
        },
      });
      dispatch({
        type: 'STOP_LOADING',
        payload: {},
      });
      dispatch({
        type: 'GET_SUBMISSIONREADY_PROCESSES',
        payload: { submissionReadyProcesses: response.data },
      });
      return response.data;
    } catch (err) {
      dispatch({
        type: 'STOP_LOADING',
        payload: {},
      });
      handleError(err);
    }
  };

  const getFundingReadyProcesses = async (limit, page, sortModel) => {
    try {
      dispatch({
        type: 'START_LOADING',
        payload: {},
      });
      const endpoint = `/v1/thg/getFundingReadyProcesses`;
      const response = await axios.get(endpoint, {
        params: {
          limit: limit,
          page: page,
          sortModel: sortModel,
        },
      });
      dispatch({
        type: 'STOP_LOADING',
        payload: {},
      });
      dispatch({
        type: 'GET_FUNDINGREADY_PROCESSES',
        payload: { fundingReadyProcesses: response.data },
      });
      return response.data;
    } catch (err) {
      dispatch({
        type: 'STOP_LOADING',
        payload: {},
      });
      handleError(err);
    }
  };

  const createManualClientClaim = async (processId) => {
    try {
      dispatch({
        type: 'START_LOADING',
        payload: {},
      });
      const endpoint = `/v1/claim/create/client/${processId}`;
      const response = await axios.post(endpoint, {});
      dispatch({
        type: 'STOP_LOADING',
        payload: {},
      });

      return response.data;
    } catch (err) {
      dispatch({
        type: 'STOP_LOADING',
        payload: {},
      });
      handleError(err);
    }
  };

  const updatePartner = async (partnerId, data) => {
    try {
      dispatch({
        type: 'START_LOADING',
        payload: {},
      });
      const endpoint = `/v1/partner/updatePartnerById/${partnerId}`;
      const response = await axios.post(endpoint, data, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      dispatch({
        type: 'STOP_LOADING',
        payload: {},
      });

      return response;
    } catch (err) {
      dispatch({
        type: 'STOP_LOADING',
        payload: {},
      });
      handleError(err);
      return err;
    }
  };

  const payoutReclaim = async (claimReceiptId) => {
    try {
      dispatch({
        type: 'START_LOADING',
        payload: {},
      });
      const endpoint = `/v1/claim/payoutReclaim/${claimReceiptId}`;
      const response = await axios.get(endpoint);

      dispatch({
        type: 'STOP_LOADING',
        payload: {},
      });

      return response;
    } catch (err) {
      dispatch({
        type: 'STOP_LOADING',
        payload: {},
      });
      handleError(err);
      return err;
    }
  };

  const reclaimBankReceived = async (claimReceiptId) => {
    try {
      dispatch({
        type: 'START_LOADING',
        payload: {},
      });
      const endpoint = `/v1/claim/reclaimBankReceived/${claimReceiptId}`;
      const response = await axios.get(endpoint);

      dispatch({
        type: 'STOP_LOADING',
        payload: {},
      });

      return response;
    } catch (err) {
      dispatch({
        type: 'STOP_LOADING',
        payload: {},
      });
      handleError(err);
      return err;
    }
  };

  const completedBankReceived = async (processId) => {
    try {
      dispatch({
        type: 'START_LOADING',
        payload: {},
      });
      const endpoint = `/v1/claim/completedBankReceived/${processId}`;
      const response = await axios.get(endpoint);

      dispatch({
        type: 'STOP_LOADING',
        payload: {},
      });

      return response;
    } catch (err) {
      dispatch({
        type: 'STOP_LOADING',
        payload: {},
      });
      handleError(err);
      return err;
    }
  };

  const setUbaFeedback = async (submissionId) => {
    try {
      dispatch({
        type: 'START_LOADING',
        payload: {},
      });
      const endpoint = `/v1/thg/setUbaFeedback/${submissionId}`;
      const response = await axios.get(endpoint);

      dispatch({
        type: 'STOP_LOADING',
        payload: {},
      });

      return response;
    } catch (err) {
      dispatch({
        type: 'STOP_LOADING',
        payload: {},
      });
      handleError(err);
      return err;
    }
  };

  const acceptSubmission = async (submissionId) => {
    try {
      dispatch({
        type: 'START_LOADING',
        payload: {},
      });
      const endpoint = `/v1/submission/accept/${submissionId}`;
      const response = await axios.get(endpoint);

      dispatch({
        type: 'STOP_LOADING',
        payload: {},
      });

      return response;
    } catch (err) {
      dispatch({
        type: 'STOP_LOADING',
        payload: {},
      });
      handleError(err);
      return err;
    }
  };

  const setLoading = async (isLoading) => {
    try {
      if (isLoading) {
        dispatch({
          type: 'START_LOADING',
          payload: {},
        });
      }

      if (!isLoading) {
        dispatch({
          type: 'STOP_LOADING',
          payload: {},
        });
      }
    } catch (err) {
      dispatch({
        type: 'STOP_LOADING',
        payload: {},
      });
      handleError(err);
      return err;
    }
  };

  const triggerImageRecognition = async (thgId) => {
    try {
      dispatch({
        type: 'START_LOADING',
        payload: {},
      });
      const endpoint = `/v1/thg/imagerecognition/${thgId}`;
      const response = await axios.get(endpoint);

      dispatch({
        type: 'TRIGGER_IMAGE_RECOGNITION',
        payload: { vehicle: response.data.vehicleData },
      });
      dispatch({
        type: 'STOP_LOADING',
        payload: {},
      });

      return response;
    } catch (err) {
      dispatch({
        type: 'STOP_LOADING',
        payload: {},
      });
      handleError(err);
      return err;
    }
  };

  const createPaybackPayout = async () => {
    try {
      dispatch({
        type: 'START_LOADING',
        payload: {},
      });
      const endpoint = `/v1/payout/createPaybackPayout`;
      const response = await axios.get(endpoint);

      dispatch({
        type: 'STOP_LOADING',
        payload: {},
      });

      return response;
    } catch (err) {
      dispatch({
        type: 'STOP_LOADING',
        payload: {},
      });
      handleError(err);
      return err;
    }
  };

  return (
    <DataContext.Provider
      value={{
        ...state,
        getBlacklist,
        updateThg,
        submitThgs,
        archiveThg,
        restoreThg,
        extendLockThg,
        rejectValidationThg,
        deleteSubscription,
        getSubscriptions,
        activateSubscription,
        deleteThg,
        getThgs,
        getThg,
        getThgsSearch,
        getUserPartner,
        validateThg,
        processPayout,
        processPayin,
        getPayoutFile,
        getPayoutTransactions,
        updateVehicle,
        getFleetClients,
        createClient,
        createPartner,
        deleteClient,
        deleteBlacklistItem,
        getFleetClient,
        updateClient,
        createVehicles,
        deleteVehicle,
        deleteApiKey,
        submitFleetVehicles,
        getDashboardData,
        getDashboardDataTS,
        getUserApiKeys,
        generateApiKey,
        changePassword,
        rotateImage,
        validateNext,
        setProcessStatus,
        setLoading,
        createInviteThg,
        getInvitesThg,
        deleteInviteThg,
        resetThgsSearch,
        downloadSubmissionFile,
        inviteUserAccount,
        setUserDeactivated,
        setUserViewRights,
        resendInviteAccount,
        setUbaStatus,
        createPayoutClient,
        getSubmissionIds,
        getExcelReport,
        getAdminRevenueReport,
        getComments,
        postComment,
        deleteComment,
        getPayouts,
        getPayins,
        updateClaims,
        updateClaim,
        payoutClaim,
        getClaims,
        getTeamUsers,
        getQuotas,
        createManualClientClaim,
        updatePartner,
        createBlacklistItem,
        getPartners,
        setReloadData,
        getCharityData,
        payoutCharity,
        payoutReclaim,
        getReclaimPaybackFile,
        reclaimBankReceived,
        completedBankReceived,
        rejectThgUba,
        setUbaFeedback,
        acceptSubmission,
        getCharityDataTS,
        getBlogs,
        createBlog,
        deleteBlogById,
        editBlogById,
        activateBlogById,
        triggerImageRecognition,
        getSubmissions,
        getSubmission,
        getPayout,
        getPayin,
        getClaim,
        getSubmissionReadyProcesses,
        getFundingReadyProcesses,
        createPaybackPayout,
      }}
    >
      {children}
    </DataContext.Provider>
  );
}

export { DataProvider, DataContext };
