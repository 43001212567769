// @mui
import { Paper, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';
import Grid from '@mui/material/Grid2';

import { SubHeadline } from './SubHeadline';
import Label from 'src/components/Label';
import { fCurrencyEuro } from 'src/utils/formatNumber';

// components

// ----------------------------------------------------------------------

// ----------------------------------------------------------------------
const labelMapping = {
  M1: 'E-PKW',
  LXe: 'E-Zweirad',
  N1: 'E-Nutzfahrzeug',
  N2: 'E-Nutzfahrzeug',
  N3: 'E-Nutzfahrzeug',
  M3: 'E-Bus',
};
const subLabelMapping = {
  M1: 'M1',
  LXe: 'L3e, L4e, L5e, L7e',
  N1: 'Klasse N1',
  N2: 'Klasse N2',
  N3: 'Klasse N3',
  M3: 'M3',
};

export default function SummaryVerify({ thgs, equalPayout }) {
  //  Same client for every submit
  const theme = useTheme();

  const { client } = thgs[0];
  const { type } = client;
  const isLight = theme.palette.mode === 'light';

  const vehicles = thgs.map((thg) => {
    return { ...thg.vehicle, charityAmount: thg.charityAmount, charityPartner: thg.charityPartner };
  });
  const uniqueVehicles = vehicles.filter((v, i, a) => a.findIndex((v2) => v2._id === v._id) === i);

  return (
    <Box sx={{ padding: '20px 0px' }}>
      <Box sx={{ marginBottom: '20px' }}>
        <Grid container spacing={10}>
          <>
            <Grid size={12}>
              {' '}
              <Box>
                <SubHeadline text="Eingaben" />
              </Box>
              <Paper sx={{ padding: '10px 20px', width: '100%' }} variant="outlined">
                <Box sx={{ display: 'flex', marginBottom: '5px' }}>
                  <strong>Beantragt als:</strong>
                  <Box sx={{ textAlign: 'right', flex: 1 }}>{type === 'b2b' ? 'Firma' : 'Privatperson'}</Box>
                </Box>

                <Box sx={{ display: 'flex', marginBottom: '5px' }}>
                  <strong>Vorname:</strong>
                  <Box sx={{ textAlign: 'right', flex: 1 }}>{client.firstName}</Box>
                </Box>

                <Box sx={{ display: 'flex', marginBottom: '5px' }}>
                  <strong>Nachname:</strong>
                  <Box sx={{ textAlign: 'right', flex: 1 }}>{client.lastName}</Box>
                </Box>
                <Box sx={{ display: 'flex', marginBottom: '5px' }}>
                  <strong>Strasse:</strong>
                  <Box sx={{ textAlign: 'right', flex: 1 }}>
                    {client.street} {client.housenumber}
                  </Box>
                </Box>

                <Box sx={{ display: 'flex', marginBottom: '5px' }}>
                  <strong>Stadt:</strong>
                  <Box sx={{ textAlign: 'right', flex: 1 }}>
                    {client.zip} {client.city}
                  </Box>
                </Box>

                {type === 'b2b' && (
                  <>
                    <Box sx={{ display: 'flex', marginBottom: '5px' }}>
                      <strong>Firmenname:</strong>
                      <Box sx={{ textAlign: 'right', flex: 1 }}>{client.companyName}</Box>
                    </Box>
                    <Box sx={{ display: 'flex', marginBottom: '5px' }}>
                      <strong>Umsatzsteuerpflichtig:</strong>
                      <Box sx={{ textAlign: 'right', flex: 1 }}>{client.salesTax ? 'Ja' : 'Nein'}</Box>
                    </Box>
                    <Box sx={{ display: 'flex', marginBottom: '5px' }}>
                      <strong>Steuernummer:</strong>
                      <Box sx={{ textAlign: 'right', flex: 1 }}>{client.taxId}</Box>
                    </Box>
                  </>
                )}

                <Box sx={{ display: 'flex', marginBottom: '5px' }}>
                  <strong>E-Mail-Adresse:</strong>
                  <Box sx={{ textAlign: 'right', flex: 1 }}>{client.email}</Box>
                </Box>
                <Box sx={{ display: 'flex', marginBottom: '5px' }}>
                  <strong>Telefon:</strong>
                  <Box sx={{ textAlign: 'right', flex: 1 }}>{client.phone}</Box>
                </Box>
                <Box sx={{ display: 'flex', marginBottom: '5px' }}>
                  <strong>Kontoinhaber:</strong>
                  <Box sx={{ textAlign: 'right', flex: 1 }}>{client.paymentRecipient}</Box>
                </Box>
                <Box sx={{ display: 'flex', marginBottom: '5px' }}>
                  <strong>IBAN:</strong>
                  <Box sx={{ textAlign: 'right', flex: 1 }}>
                    {client.iban.replace(/[^\dA-Z]/g, '').replace(/(.{4})/g, '$1 ')}
                  </Box>
                </Box>
              </Paper>
            </Grid>
            <Grid size={12} sx={{ pt: '32px!important' }}>
              <SubHeadline text={uniqueVehicles.length === 1 ? 'Ihr Fahrzeug' : 'Ihre Fahrzeuge'} />
              {uniqueVehicles.map((vehicle, index) => {
                const vehicleThgYears = thgs
                  .filter((thg) => thg.vehicle._id === vehicle._id)
                  .map((item) => item.year)
                  .sort();
                const thg = thgs.filter((thg) => thg.vehicle._id === vehicle._id)[0];
                const payoutPrice = thg.payoutPriceNet;
                const charityAmount = thg.charityAmount || 0;
                const charityPartner = thg.charityPartner;
                const payoutPriceSum = payoutPrice * vehicle.thgs.length;
                const payoutPriceSumFinal = Math.round(payoutPriceSum);

                return (
                  <Box key={index}>
                    <Paper
                      variant="outlined"
                      sx={{ padding: '10px 20px', display: 'flex', flexDirection: 'row', marginBottom: '10px' }}
                    >
                      <Box>
                        <Typography sx={{ marginLeft: '10px' }}>
                          <strong>Fahrzeugklasse:</strong>{' '}
                          {`${labelMapping[vehicle.vehicleClass]} (${subLabelMapping[vehicle.vehicleClass]})`}
                        </Typography>
                        <Typography sx={{ marginLeft: '10px' }}>
                          <strong>{`Auszahlungsbetrag: `}</strong>
                          {fCurrencyEuro(payoutPriceSumFinal - charityAmount)}

                          {client.salesTax && equalPayout ? <> (inkl. Mwst.)</> : <></>}
                        </Typography>
                        <Typography sx={{ marginLeft: '10px' }}>
                          <strong>{`Auszahlungstyp: `}</strong>
                          {thg.instantPayout ? 'Sofortauszahlung' : 'Auszahlung nach Bestätigung durch Umweltbundesamt'}
                        </Typography>
                        {charityAmount ? (
                          <Box>
                            <Typography sx={{ marginLeft: '10px' }}>
                              <strong>{`Spendenbetrag: `}</strong> {fCurrencyEuro(charityAmount)}
                              {charityAmount && charityPartner ? <span> *</span> : ''}
                            </Typography>
                          </Box>
                        ) : (
                          ''
                        )}
                        {charityPartner && (
                          <Typography sx={{ marginLeft: '10px' }}>
                            <strong>{`Spendenpartner: `}</strong> {charityPartner.name}
                          </Typography>
                        )}
                        <Typography sx={{ marginLeft: '10px' }}>
                          <strong>{`Abrechnungszeitraum: `}</strong>
                          {`${vehicleThgYears.join(', ')}`}
                        </Typography>
                      </Box>
                    </Paper>

                    {charityAmount && charityPartner ? (
                      <Typography sx={{ color: '#aaa', fontSize: '14px', ml: 2 }}>
                        * Bitte beachten Sie, dass wir keine Spendenquittung ausstellen können.
                      </Typography>
                    ) : (
                      ''
                    )}
                  </Box>
                );
              })}
            </Grid>
          </>
        </Grid>
      </Box>
    </Box>
  );
}
