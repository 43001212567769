import { TextField, Typography } from '@mui/material';
import { Box } from '@mui/system';
import Grid from '@mui/material/Grid2';

export const PayoutForm = ({
  error,
  setM1,
  m1,
  lxe,
  setLxe,
  n1,
  setN1,
  n2,
  setN2,
  n3,
  setN3,
  m3,
  setM3,
  setM1Fixed,
  m1Fixed,
  lxeFixed,
  setLxeFixed,
  n1Fixed,
  setN1Fixed,
  n2Fixed,
  setN2Fixed,
  n3Fixed,
  setN3Fixed,
  m3Fixed,
  setM3Fixed,
}) => (
  <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
    <Typography variant="h6">Auszahlungspreise (brutto)</Typography>

    <Grid container spacing={2}>
      <Grid size={{ xs: 12, md: 6, lg: 6, xl: 6 }}>
        <Box>
          <Typography sx={{ fontWeight: 600, mb: 2 }}>Sofortauszahlung</Typography>
          <TextField
            error={error.m1}
            type="number"
            value={m1}
            onChange={(e) => {
              setM1(e.target.value);
            }}
            label="Auszahlungspreis M1 SOFORT"
            name="sellPrice.M1"
            fullWidth
          />
          <TextField
            error={error.lxe}
            type="number"
            value={lxe}
            onChange={(e) => {
              setLxe(e.target.value);
            }}
            label="Auszahlungspreis LXe SOFORT"
            name="sellPrice.LXe"
            fullWidth
          />
          <TextField
            error={error.n1}
            type="number"
            value={n1}
            onChange={(e) => {
              setN1(e.target.value);
            }}
            label="Auszahlungspreis N1 SOFORT"
            name="sellPrice.N1"
            fullWidth
          />
          <TextField
            error={error.n2}
            type="number"
            value={n2}
            onChange={(e) => {
              setN2(e.target.value);
            }}
            label="Auszahlungspreis N2 SOFORT"
            name="sellPrice.N2"
            fullWidth
          />
          <TextField
            error={error.n3}
            type="number"
            value={n3}
            onChange={(e) => {
              setN3(e.target.value);
            }}
            label="Auszahlungspreis N3 SOFORT"
            name="sellPrice.N3"
            fullWidth
          />
          <TextField
            error={error.m3}
            type="number"
            value={m3}
            onChange={(e) => {
              setM3(e.target.value);
            }}
            label="Auszahlungspreis M3 SOFORT"
            name="sellPrice.M3"
            fullWidth
          />
        </Box>
      </Grid>
      <Grid size={{ xs: 12, md: 6, lg: 6, xl: 6 }}>
        <Box>
          {' '}
          <Typography sx={{ fontWeight: 600, mb: 2 }}>Auszahlung nach Bestätigung durch Umweltbundesamt</Typography>
          <TextField
            error={error.m1Fixed}
            type="number"
            value={m1Fixed}
            onChange={(e) => {
              setM1Fixed(e.target.value);
            }}
            label="Auszahlungspreis M1"
            name="sellPriceFixed.M1"
            fullWidth
          />
          <TextField
            error={error.lxeFixed}
            type="number"
            value={lxeFixed}
            onChange={(e) => {
              setLxeFixed(e.target.value);
            }}
            label="Auszahlungspreis LXe"
            name="sellPriceFixed.LXe"
            fullWidth
          />
          <TextField
            error={error.n1Fixed}
            type="number"
            value={n1Fixed}
            onChange={(e) => {
              setN1Fixed(e.target.value);
            }}
            label="Auszahlungspreis N1"
            name="sellPriceFixed.N1"
            fullWidth
          />
          <TextField
            error={error.n2Fixed}
            type="number"
            value={n2Fixed}
            onChange={(e) => {
              setN2Fixed(e.target.value);
            }}
            label="Auszahlungspreis N2"
            name="sellPriceFixed.N2"
            fullWidth
          />
          <TextField
            error={error.n3Fixed}
            type="number"
            value={n3Fixed}
            onChange={(e) => {
              setN3Fixed(e.target.value);
            }}
            label="Auszahlungspreis N3"
            name="sellPriceFixed.N3"
            fullWidth
          />
          <TextField
            error={error.m3Fixed}
            type="number"
            value={m3Fixed}
            onChange={(e) => {
              setM3Fixed(e.target.value);
            }}
            label="Auszahlungspreis M3"
            name="sellPriceFixed.M3"
            fullWidth
          />
        </Box>
      </Grid>
    </Grid>
  </div>
);
