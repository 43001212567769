// @mui
import { styled } from '@mui/material/styles';
import { Container, Typography } from '@mui/material';
import Grid from '@mui/material/Grid2';

// components
import Page from 'src/components/Page';
import FaqsList from './FaqsList';

// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
  paddingTop: theme.spacing(2),
  [theme.breakpoints.up('md')]: {
    paddingTop: theme.spacing(2),
  },
}));

// ----------------------------------------------------------------------

export default function GreenfactoryFaqs() {
  const faqData = [
    {
      value: 'panel1',
      heading: 'Was ist die THG-Quote?',
      detail:
        'Mit der Treibhausgas-Minderungsquote (THG-Quote) möchte die Bundesregierung die CO₂-Emissionen im Straßenverkehr um 25 Prozent bis zum Jahr 2030 mindern. Da rein batterieelektrischen Elektrofahrzeugen (BEV) im Straßenverkehr keine CO₂-Emissionen produzieren, können Halter dieser Fahrzeug seit dem 01.01.2022 die eingesparten CO₂-Emissionen zertifizieren lassen und über Dritte an Mineralölunternehmen vermarkten.',
    },
    {
      value: 'panel2',
      heading: 'Ist die THG-Quote gesetzlich verankert?',
      detail:
        'Ja. Die gesetzliche Grundlage bildet das Bundes-Immissionsschutzgesetz (BlmSchG § 37a) sowie die Verordnung zur Festlegung weiterer Bestimmungen zur Treibhausgasminderung bei Kraftstoffen (38. BImSchV vom 8. Dezember 2017).',
    },
    {
      value: 'panel3',
      heading: 'Wie lange wird es die THG-Quote geben?',
      detail:
        'Nach derzeitigem Kenntnisstand wird die THG-Quote bis zum Jahr 2030 anrechenbar sein. Ein Verlängerung scheint durchaus möglich, da über das Jahr 2030 hinaus weitere Klimaziele erreicht werden sollen.',
    },
    {
      value: 'panel4',
      heading: 'Wer hat Anspruch auf die THG-Quotenprämie?',
      detail:
        'Ausschließlich Halter von rein batterieelektrischen Elektrofahrzeugen (BEV) sind seit dem 01.01.2022 berechtigt, am THG-Quotenhandel teilzunehmen. Dies gilt sowohl für privat, als auch für geschäftlich genutzte E-Fahrzeuge. Sobald in Ihrem Fahrzeug jedoch ein Verbrennungsmotor verbaut ist, beispielsweise bei Plug-in-Hybrid-Fahrzeuge, erfüllen Sie diese Voraussetzung nicht.',
    },
    {
      value: 'panel13',
      heading: 'Welche Fahrzeugklassen sind für die THG-Quote zugelassen?',
      detail:
        'Mit dem 29.07.2023 trat eine überarbeitete THG-Verordnung in Kraft, die freiwillig zugelassene Fahrzeuge wie E-Roller oder Kleinkrafträder vom THG-Quotenhandel ausschließt. <br/><br/><b>Zertifizierbare Fahrzeugklassen:</b> M1, N1, N2, N3, M3, L3e, L3e-A2, L3e-A3, L4e, L4e-A2, L4e-A3, L5e, L5e-A, L5e-B, L7e, L7e-A1, L7e-A2, L7e-B1, L7e-B2, L7e-CP, L7e-CU<br/><br/><b>Nicht mehr zertifizierbare Fahrzeugklassen:</b> L1e, L1e-A, L1e-B, L2e, L2e-P, L2e-U, L3e-A1, L3e (Art des Aufbaus = Feld 4 = B), L4e-A1, L6e-A, L6e-BP, L6e-BU, 24, 25',
    },
    {
      value: 'panel5',
      heading: 'Wie hoch ist die THG-Quotenprämie?',
      detail:
        'Die von uns ausgezahlte THG-Quotenprämie entspricht der Höhe, wie es Ihnen bei der Einreichung der Daten Ihres Elektrofahrzeugs angezeigt wurde. Der Abzug einer Provision erfolgt bei uns nicht.',
    },
    {
      value: 'panel6',
      heading: 'Wann und wie erhalte ich die THG-Quotenprämie?',
      detail:
        'Nach erfolgreicher, interner Prüfung Ihrer Daten durch uns zahlen wir die THG-Quotenprämie per Sofortgutschrift innerhalb von wenigen Tagen auf die angegebene Kontoverbindung aus. ',
    },
    { value: 'panel7', heading: 'Was kostet dieser Service?', detail: 'Der Service ist für Sie kostenlos.' },
    {
      value: 'panel8',
      heading: 'Wie oft erhalte ich die THG-Quotenprämie?',
      detail:
        'Solange Sie Halter eines rein batterieelektrischen Elektrofahrzeugs sind, können Sie Ihre THG-Quote jedes Jahr neu beantragen und vermarkten.',
    },
    {
      value: 'panel9',
      heading: 'Welche Daten werden benötigt?',
      detail:
        'Für die Prüfung und Auszahlung Ihrer THG-Quotenprämie ist die Übermittlung jeweils einer Kopie der Vorderseite Ihres Fahrzeugscheins (Teil 1), Ihre Kontaktdaten und Ihre Bankverbindung notwendig. Sobald diese Daten uns vollständig vorliegen, startet unverzüglich der Vermarktungsprozess. Über den Fortschritt des Vermarktungsprozesses werden Sie stets über Ihre angegebene E-Mailadresse informiert.',
    },
    {
      value: 'panel10',
      heading: 'Ist eine Anmeldung oder Registrierung erforderlich?',
      detail:
        'Nein, der Vermarktungsprozess Ihrer THG-Quote erfolgt bei uns sehr zügig und digital. Auch die Auszahlung erfolgt bei uns innerhalb weniger Tage. Daher ist eine Registrierung bei uns nicht erforderlich. Über den Fortschritt des Vermarktungsprozesses werden Sie stets über Ihre angegebene E-Mailadresse absolut tranparent informiert.',
    },
    {
      value: 'panel11',
      heading: 'Kann ich meine THG-Quote auch selbst vermarkten?',
      detail:
        'Als Halter eines E-Fahrzeugs können Sie sich vom Umweltbundesamt (UBA) die für den Quotenhandel notwendige Zertifizierung ausstellen lassen, jedoch ist die Vermarktung einzelner Zertifikate an Großabnehmer, wie Mineralölkonzerne, kaum möglich. Daher übernehmen wir für Sie diesen Vermarktung über unsere Quotendirektkäufer.',
    },
    {
      value: 'panel12',
      heading: 'Muss ich meine THG-Quotenprämie versteuern?',
      detail:
        'Als Privatperson müssen Sie den Erlös nach aktueller Gesetzeslage nicht versteuern. Als Unternehmer erhalten Sie von uns einen Auszahlungsbeleg mit ausgewiesener Umsatzsteuer, welcher entsprechend zu versteuern ist.',
    },
  ];
  return (
    <Page title="Faqs" sx={{ display: 'flex', flexGrow: 1, margin: 'auto' }}>
      <RootStyle>
        {/* <FaqsHero /> */}

        <Container sx={{ mt: 15, mb: 10, position: 'relative' }}>
          {/* <FaqsCategory /> */}

          <Typography variant="h3" sx={{ mb: 5 }}>
            Häufig gestellte Fragen
          </Typography>

          <Grid container spacing={10}>
            <Grid size={12}>
              <FaqsList faqData={faqData} />
            </Grid>
            {/* <Grid  size={12}>
          <FaqsForm />
        </Grid> */}
          </Grid>
        </Container>
      </RootStyle>
    </Page>
  );
}
