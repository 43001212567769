import PropTypes from 'prop-types';
// @mui
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CreditScoreIcon from '@mui/icons-material/CreditScore';
import ErrorIcon from '@mui/icons-material/Error';
import EmailIcon from '@mui/icons-material/Email';
import { Step, StepLabel, Stepper, Typography } from '@mui/material';
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';
import { styled, useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import PriceCheckIcon from '@mui/icons-material/PriceCheck';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import FactCheckIcon from '@mui/icons-material/FactCheck';
import Iconify from 'src/components/Iconify';

import dayjs from 'dayjs';

// ----------------------------------------------------------------------
StatusTimeLineVertical.propTypes = {
  thgProcess: PropTypes.object,
};

export default function StatusTimeLineVertical({ thgProcess }) {
  const theme = useTheme();

  const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
    [`&.${stepConnectorClasses.alternativeLabel}`]: {
      top: 22,
    },
    [`&.${stepConnectorClasses.active}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        background: theme.palette.primary.main,
      },
    },
    [`&.${stepConnectorClasses.completed}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        background: theme.palette.primary.main,
      },
    },
    [`& .${stepConnectorClasses.line}`]: {
      height: 3,
      border: 0,
      backgroundColor: theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#eaeaf0',
      borderRadius: 1,
    },
  }));

  const ColorlibStepIconRoot = styled('div')(({ theme, ownerState, error }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#ccc',
    zIndex: 1,
    color: '#fff',
    width: 50,
    height: 50,
    display: 'flex',
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center',
    ...(ownerState.active && {
      background: error ? theme.palette.error.main : theme.palette.primary.main,
    }),
    ...(ownerState.completed && {
      background: error ? theme.palette.error.main : theme.palette.primary.main,
    }),
  }));

  const renderIcon = (iconTag) => (
    <Iconify icon={iconTag} width={25} height={25} color={'rgba(255,255,255,1)'} style={{ verticalAlign: '-5' }} />
  );

  function ColorlibStepIcon(props) {
    const { active, completed, className, icon, error } = props;
    const icons = thgProcess.instantPayout
      ? {
          1: renderIcon('mdi:paper-add'),
          2:
            thgStatus === 'rejected' && rejectionType === 'validation' ? (
              <ErrorIcon />
            ) : (
              renderIcon('ic:baseline-fact-check')
            ),
          3: thgStatus === 'rejected' && rejectionType === 'transmission' ? <ErrorIcon /> : <UploadFileIcon />,
          4: renderIcon('mdi:auto-pay'),
          5: renderIcon('mdi:euro'),
          6:
            (claimProcess && claimProcess.isManual) || (thgStatus === 'rejected' && rejectionType === 'uba') ? (
              <ErrorIcon />
            ) : (
              <CreditScoreIcon />
            ),
          7:
            thgProcess.instantPayout && thgProcess.charityAmount && thgProcess.charityAmount > 0 ? (
              <Iconify icon={'mdi:charity-outline'} />
            ) : claimProcess && claimProcess.isManual ? (
              <PriceCheckIcon sx={{ color: '#FFF' }} />
            ) : claimProcess && !claimProcess.isManual ? (
              <EmailIcon />
            ) : (
              <CheckCircleIcon />
            ),
          8: <PriceCheckIcon sx={{ color: '#FFF' }} />,
          9: <Iconify icon={'mdi:charity-outline'} />,
        }
      : {
          1: renderIcon('mdi:paper-add'),
          2: thgStatus === 'rejected' && rejectionType === 'validation' ? <ErrorIcon /> : <FactCheckIcon />,
          3: thgStatus === 'rejected' && rejectionType === 'transmission' ? <ErrorIcon /> : <UploadFileIcon />,
          4: renderIcon('carbon:delivery-parcel'),
          // 5: thgStatus === 'rejected' && rejectionType === 'uba' ? <CreditScoreIcon /> : <CreditScoreIcon />,
          5: <CreditScoreIcon />,
          6: renderIcon('mdi:euro'),
        };

    return (
      <ColorlibStepIconRoot ownerState={{ completed, active }} className={className} error={error}>
        {icons[icon]}
      </ColorlibStepIconRoot>
    );
  }
  const isMobileView = !useMediaQuery(theme.breakpoints.up('md'));
  const claimProcess = thgProcess.claims.find((claim) => claim.type === 'client');
  const claimIsManual = claimProcess?.isManual;
  const thgStatus = thgProcess.status;
  const rejectionType = thgProcess.rejectionType;

  //  Mobile Timeline
  const stepsValuesInstantPayout = [
    'new',
    thgStatus === 'rejected' && rejectionType === 'validation' ? 'rejected' : 'validated',
    thgStatus === 'rejected' && rejectionType === 'transmission' ? 'rejected' : 'submitted',
    'sold',
    'paidOut',
    thgStatus === 'rejected' && rejectionType === 'uba' ? 'rejected' : 'completed',
    'rejected',
  ];

  const stepsValuesFixedPayout = [
    'new',
    thgStatus === 'rejected' && rejectionType === 'validation' ? 'rejected' : 'validated',
    thgStatus === 'rejected' && rejectionType === 'transmission' ? 'rejected' : 'submitted',
    thgProcess.timestamp_submissionAt ? 'sold' : 'sold',
    'readyForPayout',
    thgStatus === 'rejected' && rejectionType === 'uba' ? 'rejected' : 'paidOut' || 'completed',
    'completed',
    'rejected',
  ];

  const stepsValues = thgProcess.instantPayout ? stepsValuesInstantPayout : stepsValuesFixedPayout;

  //  Because there is no step for readyForPaout to display a user
  let usedStatus = null;
  if (thgProcess.instantPayout) {
    usedStatus =
      thgProcess.status === 'readyForPayout' || thgProcess.status === 'waitingForUba' ? 'sold' : thgProcess.status;
  } else {
    usedStatus =
      thgProcess.status === 'waitingForUba'
        ? thgProcess.timestamp_submissionAt
          ? 'sold'
          : 'validated'
        : thgProcess.status;
  }

  let actualStep = stepsValues.indexOf(usedStatus);

  if (claimProcess) {
    if (claimProcess.isManual) {
      actualStep = 5;
      if (claimProcess.status === 'bankReceived' || claimProcess.status === 'completed') {
        actualStep = 6;
      }
    } else {
      actualStep = 5;
      if (claimProcess.status === 'requested') {
        actualStep = 6;
      }
      if (claimProcess.status === 'bankReceived' || claimProcess.status === 'completed') {
        actualStep = 7;
      }
    }
  }

  const ubaRejected = thgStatus === 'rejected' && rejectionType === 'uba';
  return (
    <Stepper alternativeLabel activeStep={actualStep} connector={<ColorlibConnector />}>
      <Step>
        <StepLabel error={false} StepIconComponent={ColorlibStepIcon}>
          <Typography sx={{ fontSize: '16px' }}>Vermarktung beauftragt</Typography>
          <Typography sx={{ fontSize: '0.8rem' }}>
            {' '}
            {dayjs(thgProcess.timestamp_verifiedAt || thgProcess.timestamp_createdAt).format('DD.MM.YYYY HH:mm')}{' '}
          </Typography>
        </StepLabel>
      </Step>
      {thgStatus === 'rejected' && rejectionType === 'validation' ? (
        <Step>
          <StepLabel error={true} StepIconComponent={ColorlibStepIcon}>
            <Typography sx={{ fontSize: '16px' }}>Abgelehnt bei der Datenprüfung</Typography>
            {thgProcess.timestamp_rejectedAt && (
              <Typography sx={{ fontSize: '0.8rem' }}>
                {' '}
                {dayjs(thgProcess.timestamp_rejectedAt).format('DD.MM.YYYY HH:mm')}{' '}
              </Typography>
            )}
          </StepLabel>
        </Step>
      ) : (
        <Step>
          <StepLabel error={false} StepIconComponent={ColorlibStepIcon}>
            <Typography sx={{ fontSize: '16px' }}>Datenprüfung durchgeführt</Typography>
            {thgProcess.timestamp_validatedAt && (
              <Typography sx={{ fontSize: '0.8rem' }}>
                {' '}
                {dayjs(thgProcess.timestamp_validatedAt).format('DD.MM.YYYY HH:mm')}{' '}
              </Typography>
            )}
          </StepLabel>
        </Step>
      )}
      {thgStatus === 'rejected' && rejectionType === 'transmission' ? (
        <Step>
          <StepLabel error={true} StepIconComponent={ColorlibStepIcon}>
            <Typography sx={{ fontSize: '16px' }}>Vom Quotenhändler abgelehnt</Typography>

            {thgProcess.timestamp_rejectedAt && (
              <Typography sx={{ fontSize: '0.8rem' }}>
                {' '}
                {dayjs(thgProcess.timestamp_rejectedAt).format('DD.MM.YYYY HH:mm')}{' '}
              </Typography>
            )}
          </StepLabel>
        </Step>
      ) : (
        // <Step>
        //   <StepLabel error={false} StepIconComponent={ColorlibStepIcon}>
        //     <Typography sx={{ fontSize: '16px' }}>Fahrzeugdaten eingereicht</Typography>
        //     {thgProcess.timestamp_submittedAt && (
        //       <Typography sx={{ fontSize: '0.8rem' }}>
        //         {' '}
        //         {dayjs(thgProcess.timestamp_submittedAt).format('DD.MM.YYYY HH:mm')}{' '}
        //       </Typography>
        //     )}
        //   </StepLabel>
        // </Step>
        <></>
      )}
      <Step>
        <StepLabel error={false} StepIconComponent={ColorlibStepIcon}>
          <Typography sx={{ fontSize: '16px' }}>
            {' '}
            {thgProcess.instantPayout ? 'Zur Vorauszahlung vorgemerkt' : 'THG-Quote beim UBA eingereicht'}
          </Typography>
          {(!thgProcess.instantPayout && thgProcess.timestamp_submissionAt) ||
          (thgProcess.instantPayout && thgProcess.timestamp_soldAt) ? (
            <Typography sx={{ fontSize: '0.8rem' }}>
              {' '}
              {dayjs(thgProcess.instantPayout ? thgProcess.timestamp_soldAt : thgProcess.timestamp_submissionAt).format(
                'DD.MM.YYYY HH:mm'
              )}{' '}
            </Typography>
          ) : (
            <></>
          )}
        </StepLabel>
      </Step>
      {thgProcess.instantPayout && (
        <Step>
          <StepLabel error={false} StepIconComponent={ColorlibStepIcon}>
            <Typography sx={{ fontSize: '16px' }}> Vorauszahlung erfolgt</Typography>
            {thgProcess.timestamp_paidOutAt && (
              <Typography sx={{ fontSize: '0.8rem' }}>
                {' '}
                {dayjs(thgProcess.timestamp_paidOutAt).format('DD.MM.YYYY HH:mm')}{' '}
              </Typography>
            )}
          </StepLabel>
        </Step>
      )}
      {ubaRejected && (
        <Step>
          <StepLabel error={true} StepIconComponent={ColorlibStepIcon}>
            <Typography sx={{ fontSize: '16px' }}>Ihr Antrag wurde vom Umweltbundesamt abgelehnt</Typography>
            {thgProcess.timestamp_rejectedAt && (
              <Typography sx={{ fontSize: '0.8rem' }}>
                {' '}
                {dayjs(thgProcess.timestamp_rejectedAt).format('DD.MM.YYYY HH:mm')}{' '}
              </Typography>
            )}
          </StepLabel>
        </Step>
      )}
      {ubaRejected && claimProcess && !claimProcess.isManual && (
        <Step>
          <StepLabel error={false} StepIconComponent={ColorlibStepIcon}>
            <Typography sx={{ fontSize: '16px' }}>Rückzahlung angefordert</Typography>
            <Typography sx={{ fontSize: '0.8rem' }}>
              {' '}
              {claimProcess.timestamp_requestedAt &&
                dayjs(claimProcess.timestamp_requestedAt).format('DD.MM.YYYY HH:mm')}
            </Typography>
          </StepLabel>
        </Step>
      )}
      {ubaRejected && claimProcess && !claimProcess.isManual && (
        <Step>
          <StepLabel error={false} StepIconComponent={ColorlibStepIcon}>
            <Typography sx={{ fontSize: '16px' }}>Rückzahlung erhalten</Typography>
            <Typography sx={{ fontSize: '0.8rem' }}>
              {' '}
              {claimProcess.timestamp_bankReceivedAt &&
                dayjs(claimProcess.timestamp_bankReceivedAt).format('DD.MM.YYYY HH:mm')}
            </Typography>
          </StepLabel>
        </Step>
      )}
      {!ubaRejected && claimProcess && claimProcess.isManual && (
        <Step>
          <StepLabel error={true} StepIconComponent={ColorlibStepIcon}>
            <Typography sx={{ fontSize: '16px' }}>
              {' '}
              Vermarktung gestoppt
              <br />
              Rückzahlung angefordert
            </Typography>
            <Typography sx={{ fontSize: '0.8rem' }}>
              {' '}
              {claimProcess.created_at && dayjs(claimProcess.created_at).format('DD.MM.YYYY HH:mm')}
            </Typography>
          </StepLabel>
        </Step>
      )}
      {!ubaRejected && claimProcess && claimProcess.isManual && (
        <Step>
          <StepLabel error={false} StepIconComponent={ColorlibStepIcon}>
            <Typography sx={{ fontSize: '16px' }}>Rückzahlung erhalten</Typography>
            <Typography sx={{ fontSize: '0.8rem' }}>
              {' '}
              {claimProcess.timestamp_bankReceivedAt &&
                dayjs(claimProcess.timestamp_bankReceivedAt).format('DD.MM.YYYY HH:mm')}
            </Typography>
          </StepLabel>
        </Step>
      )}
      {!ubaRejected && (!claimProcess || (claimProcess && !claimProcess.isManual)) && (
        <Step>
          <StepLabel error={thgStatus === 'rejected' && rejectionType === 'uba'} StepIconComponent={ColorlibStepIcon}>
            <Typography sx={{ fontSize: '16px' }}>
              {' '}
              {thgStatus === 'rejected' && rejectionType === 'uba'
                ? 'THG-Quote vom Umweltbundesamt abgelehnt'
                : 'THG-Quote vom Umweltbundesamt bestätigt'}
            </Typography>
            <Typography sx={{ fontSize: '0.8rem' }}>
              {' '}
              {thgProcess.instantPayout
                ? thgProcess.timestamp_completedAt && dayjs(thgProcess.timestamp_completedAt).format('DD.MM.YYYY HH:mm')
                : thgProcess.timestamp_readyForPayoutAt &&
                  dayjs(thgProcess.timestamp_readyForPayoutAt).format('DD.MM.YYYY HH:mm')}
            </Typography>
          </StepLabel>
        </Step>
      )}
      {!thgProcess.instantPayout && (
        <Step>
          <StepLabel error={false} StepIconComponent={ColorlibStepIcon}>
            <Typography sx={{ fontSize: '16px' }}> Auszahlung erfolgt</Typography>
            <Typography sx={{ fontSize: '0.8rem' }}>
              {' '}
              {thgProcess.timestamp_paidOutAt && dayjs(thgProcess.timestamp_paidOutAt).format('DD.MM.YYYY HH:mm')}
            </Typography>
          </StepLabel>
        </Step>
      )}
      {thgProcess.charityAmount && thgProcess.charityAmount > 0 && (
        <Step>
          <StepLabel StepIconComponent={ColorlibStepIcon}>
            <Typography sx={{ fontSize: '16px' }}> {'Spende ausgezahlt'}</Typography>
            <Typography sx={{ fontSize: '0.8rem' }}>
              {' '}
              {thgProcess.timestamp_completedAt && dayjs(thgProcess.timestamp_completedAt).format('DD.MM.YYYY HH:mm')}
            </Typography>
          </StepLabel>
        </Step>
      )}
    </Stepper>
  );
}
