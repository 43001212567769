import { Checkbox, FormControlLabel, TextField, Typography } from '@mui/material';

export const DataFormOptions = ({
  error,
  notificationEmail,
  setNotificationEmail,
  phoneVerification,
  setPhoneVerification,
  vehicleClasses,
  payoutModes,
  handleVehicleClassChange,
  handlePayoutModeChange,
  onlySecureIntegration,
  handleOnlySecureIntegrationChange,
  showFooter,
  showHeader,
  handleShowFooterChange,
  handleShowHeaderChange,
  setMultipleVehicles,
  multipleVehicles,
  setShowCharity,
  setShowHelp,
  setNoVerificationEmail,
  setEqualPayout,
  setShowIntropage,
  showCharity,
  showHelp,
  noVerificationEmail,
  equalPayout,
  deactivated,
  setDeactivated,
  showIntropage,
}) => {
  return (
    <>
      <Typography variant="h6">Dataform Optionen</Typography>
      <div style={{ display: 'flex', flexDirection: 'column', gap: '10px', paddingLeft: '20px' }}>
        <Typography
          variant="body2"
          sx={{ color: error.vehicleClasses ? 'red' : '', marginBottom: '-10px', marginTop: '20px' }}
        >
          {error.vehicleClasses ? 'Mindestens eine Fahrzeugklasse muss gewählt werden' : 'Fahrzeugklassen'}
        </Typography>
        <div style={{ display: 'flex', flexDirection: 'row', gap: '10px' }}>
          <FormControlLabel
            control={<Checkbox checked={vehicleClasses.M1} onChange={handleVehicleClassChange} name="M1" />}
            label="M1"
          />

          <FormControlLabel
            control={<Checkbox checked={vehicleClasses.LXe} onChange={handleVehicleClassChange} name="LXe" />}
            label="LXe"
          />

          <FormControlLabel
            control={<Checkbox checked={vehicleClasses.N1} onChange={handleVehicleClassChange} name="N1" />}
            label="N1"
          />
          <FormControlLabel
            control={<Checkbox checked={vehicleClasses.N2} onChange={handleVehicleClassChange} name="N2" />}
            label="N2"
          />
          <FormControlLabel
            control={<Checkbox checked={vehicleClasses.N3} onChange={handleVehicleClassChange} name="N3" />}
            label="N3"
          />

          <FormControlLabel
            control={<Checkbox checked={vehicleClasses.M3} onChange={handleVehicleClassChange} name="M3" />}
            label="M3"
          />
        </div>
        <Typography
          variant="body2"
          sx={{ color: error.payoutModes ? 'red' : '', marginBottom: '-10px', marginTop: '20px' }}
        >
          {error.payoutModes ? 'Mindestens eine Auszahlungsmethode muss gewählt werden' : 'Auszahlungsmethoden'}
        </Typography>
        <div style={{ display: 'flex', flexDirection: 'row', gap: '10px' }}>
          <FormControlLabel
            control={<Checkbox checked={payoutModes.instant} onChange={handlePayoutModeChange} name="instant" />}
            label="instant"
          />

          <FormControlLabel
            control={<Checkbox checked={payoutModes.fixed} onChange={handlePayoutModeChange} name="fixed" />}
            label="fixed"
          />
        </div>
        <FormControlLabel
          control={
            <Checkbox
              checked={multipleVehicles}
              onChange={(e) => {
                setMultipleVehicles(e.target.checked);
              }}
              name="multivehicles"
            />
          }
          label="Mehrere Fahrzeuge"
        />

        <FormControlLabel
          control={<Checkbox checked={onlySecureIntegration} onChange={handleOnlySecureIntegrationChange} />}
          label="Nur sichere Integration"
        />

        <FormControlLabel
          control={<Checkbox checked={showFooter} onChange={handleShowFooterChange} />}
          label="Footer anzeigen"
        />

        <FormControlLabel
          control={<Checkbox checked={showHeader} onChange={handleShowHeaderChange} />}
          label="Header anzeigen"
        />

        <FormControlLabel
          control={
            <Checkbox
              checked={showCharity}
              onChange={(e) => {
                setShowCharity(e.target.checked);
              }}
            />
          }
          label="Spendenoption anzeigen"
        />

        <FormControlLabel
          control={
            <Checkbox
              checked={showHelp}
              onChange={(e) => {
                setShowHelp(e.target.checked);
              }}
            />
          }
          label="Hilfstext anzeigen"
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={noVerificationEmail}
              onChange={(e) => {
                setNoVerificationEmail(e.target.checked);
              }}
            />
          }
          label="Keine Verifizierung-Email"
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={equalPayout}
              onChange={(e) => {
                setEqualPayout(e.target.checked);
              }}
            />
          }
          label="Gleiche Brutto/Netto Auszahlungsbeträge"
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={showIntropage}
              onChange={(e) => {
                setShowIntropage(e.target.checked);
              }}
            />
          }
          label="Einführungsseite anzeigen"
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={deactivated}
              onChange={(e) => {
                setDeactivated(e.target.checked);
              }}
            />
          }
          label="Service nicht verfügbar"
        />
      </div>

      <TextField
        error={error.notificationEmail}
        sx={{ marginTop: '10px' }}
        value={notificationEmail}
        onChange={(e) => {
          setNotificationEmail(e.target.value);
        }}
        type="email"
        label="Notification Email"
        name="notificationEmail"
        fullWidth
      />
      <FormControlLabel
        control={
          <Checkbox
            checked={phoneVerification}
            onChange={(e) => {
              setPhoneVerification(e.target.checked);
            }}
          />
        }
        label="SMS-Verifizierung für Sofort-Auszahlung"
      />
    </>
  );
};
